<template>
  <div
      :class="[bookingWindow ? $style.rbs__amenities_calendar_widget_window : $style.rbs__amenities_calendar_widget_page]">
    <div :class="$style.rbs__amenities_calendar_widget_days">
      <div
          :class="[bookingWindow ? $style.rbs__amenities_calendar_widget_window_cell : $style.rbs__amenities_calendar_widget_page_cell]"
          v-for="day in daysWeek"
          :key="day.idx">
        <span
            :class="[bookingWindow ? $style.rbs__amenities_calendar_widget_window_dayweek : $style.rbs__amenities_calendar_widget_page_dayweek]">
          {{ day.name.substring(0, 2) }}
        </span>
      </div>
    </div>
    <div
        :class="[bookingWindow ? $style.rbs__amenities_calendar_widget_window_cells : $style.rbs__amenities_calendar_widget_page_cells]">
      <div
          @click="selectBookingDate(item)"
          :class="[
            bookingWindow ? $style.rbs__amenities_calendar_widget_window_cell : $style.rbs__amenities_calendar_widget_page_cell,
            !item.currentMonth ? $style.rbs__amenities_calendar_widget_page_disabled: null]"
          :style="[item.status === 'busy' || isBeforeCurrentDate(item.id) ? busy : free, item.currentDate ? currentDate : null]"
          v-for="item in items"
          :key="item.id">
        <span
            :class="[bookingWindow ? $style.rbs__amenities_calendar_widget_window_daymonth : $style.rbs__amenities_calendar_widget_page_daymonth]">{{
            item.date
          }}</span>
      </div>
    </div>
  </div>
</template>

<script>

import moment from "moment";
import {useTimelines} from '@/composition/widgets/amenities/useTimeLines.js'

export default {
  name: "amenities-calendar-widget-component",
  data: () => ({
    daysWeek: [
      {name: 'Monday', idx: 1},
      {name: 'Tuesday', idx: 2},
      {name: 'Wednesday', idx: 3},
      {name: 'Thursday', idx: 4},
      {name: 'Friday', idx: 5},
      {name: 'Saturday', idx: 6},
      {name: 'Sunday', idx: 0}],
    blockedDays: [],
    indicate: {
      busy: '#ee7071bf',
      free: '#28c76fbf',
      current: '#28c76fbf',
    },
  }),
  setup() {
    return {
      useTimelines
    }
  },
  created() {
    this.getBlockedDays()
  },
  methods: {
    isBeforeCurrentDate(date) {
      return moment(date).isBefore(moment().format('YYYY-MM-DD'))
    },
    getBlockedDays() {
      this.workingHours.forEach(el => {
        if (!el.startTime || !el.endTime) {
          let day = this.daysWeek.find(day => day.name === el.day)
          if (day?.name) this.blockedDays.push(day.idx)
        }
      })
    },
    isBlocked(num) {
      return this.blockedDays.find(el => +el === +num)
    },
    selectBookingDate(item) {
      this.$store.dispatch('getSelectedBookingDate', item)
      this.$store.dispatch('getTimeLine', item)
    },
    getStartingPointFromToCustomTimeLine(timeLine, time) {
      const idxTime = timeLine.indexOf(timeLine.find(el => el === time))
      return timeLine[idxTime]
    },
    getEndPointToCustomTimeLine(timeLine, time) {
      const idxTime = timeLine.indexOf(timeLine.find(el => el === time))
      return timeLine[idxTime]
    },
    getStatusToCustomTimeLine(timeline, time) {
      const dayIsBusy = this.timesBusyToCustomTimeLine.indexOf(time) !== -1
      if (dayIsBusy) {
        return 'BUSY'
      } else {
        return 'FREE'
      }
    },
    isDayAllReservedCustomTimeLine(el) {
      if (this.detailsAmenityPage) {
        let currentDate = moment(el.id).format('MM/DD/YYYY')

        let facilityTimesFromCurrentDate = this.facilityTimes.filter(el => el.fromDate === currentDate)
        let workingHours = this.workingHours ? this.workingHours[el.dayNumber] : null
        let busyTimes = []

        if (facilityTimesFromCurrentDate.length && workingHours) {
          facilityTimesFromCurrentDate.forEach(el => {
            const timeLine = this.useTimelines(workingHours.startTime, workingHours.endTime)

            const from = this.getStartingPointFromToCustomTimeLine(timeLine, el.from)
            const to = this.getEndPointToCustomTimeLine(timeLine, el.to)

            if (from && to)
              busyTimes = busyTimes.concat(timeLine.slice(timeLine.indexOf(from), timeLine.indexOf(to)))
          })

          let reservedTimes = []
          if (busyTimes.length) {
            let spliceTimeLine = this.useTimelines(workingHours.startTime, workingHours.endTime).slice(2, -3)
            spliceTimeLine.forEach(item => {
              if (busyTimes.find(el => el === item)) reservedTimes.push(item)
            })
            return spliceTimeLine.length === reservedTimes.length
          }
        }
        return false
      }
    }
  },
  computed: {
    detailsAmenityPage() {
      return this.$store.getters.detailsAmenityPage
    },
    facilityTimes() {
      return this.$store.getters.facilityTimes
    },
    items() {
      let days = this.$store.getters.viewPortDates

      days.map(el => {
        this.isBlocked(el.dayNumber) || this.isDayAllReservedCustomTimeLine(el)
            ? el.status = 'busy'
            : el.status = 'free'
      })
      return this.$store.getters.viewPortDates
    },
    workingHours() {
      return this.$store.getters.detailsAmenity?.workingHours
    },
    busy() {
      return `background: ${this.indicate.busy}; pointer-events: none;`
    },
    free() {
      return `background: ${this.indicate.free}; cursor: pointer;`
    },
    currentDate() {
      return `background: ${this.indicate.current} !important; color: #ffffff; font-weight: 700;`
    },
    bookingWindow() {
      return this.$store.getters.bookWindowView
    }
  }
}
</script>

<style module lang="scss" src='../../../../../../assets/styles/amenities/styles.scss'/>