<template>
2
</template>

<script>
export default {
  name: "amenities-details-documents-component"
}
</script>

<style module lang="scss" src='../../../../../assets/styles/amenities/styles.scss'/>