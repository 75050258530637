import {ref} from 'vue'

export const useTabs = items => {
    const tabs = ref(items)
    const showContentNumber = ref(1)
    const changeActiveTab = index => {
        tabs.value.forEach(el => el.isActive = false);
        tabs.value[index].isActive = true;
        showContentNumber.value = index + 1
    }
    return {
        showContentNumber,
        changeActiveTab
    }
}