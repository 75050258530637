import {onMounted, ref} from 'vue'

const resizeWindow = responsiveWidth => {
    return window.innerWidth < responsiveWidth
}

export const useResponsive = (responsiveWidth = 768) => {
    let responsive = ref(false)
    onMounted(() => {
        responsive.value = resizeWindow(responsiveWidth)
        window.addEventListener('resize', () => {
            responsive.value = resizeWindow(responsiveWidth)
        })
    })
    return {responsive}
}