import axios from 'axios'
import { getWebApiBaseUrl } from '@/services/global-config';
export const baseURL = getWebApiBaseUrl();

const loginUrl = `${baseURL}/Account/login`
const refreshTokenUrl = `${baseURL}/Account/refresh-token`

const defaultUserConfig = {
    'email': 'help@oncierge.com',
    'password': '123123',
    'passwordEncoded': false
}

const api = axios.create({
    baseURL,
    withCredentials: false
})

api.interceptors.request.use((config) => {
    if (localStorage.getItem('tokens')) {
        let token = JSON.parse(localStorage.getItem('tokens')).accessToken
        config.headers.authorization = `Bearer ${token}`
    }
    return config
}, (error) => {
    return Promise.reject(error)
})

api.interceptors.response.use((config) => {
    if (localStorage.getItem('tokens')) {
        config.headers.authorization = `Bearer ${JSON.parse(localStorage.getItem('tokens')).accessToken}`
        config.headers.common = {
            'Content-Type': 'application/json',
            'Accept': 'application/json'
        }
    }
    return config
}, async error => {
    if (error.response?.status === 401) {
        if (localStorage.getItem('tokens')) {
            let refreshToken = JSON.parse(localStorage.getItem('tokens')).refreshToken
            return api.post(refreshTokenUrl, {refreshToken}).then(res => {
                setData(res)
                error.config.headers.authorization = `Bearer ${res.data.accessToken}`
                return axios.request(error.config)
            }).catch(() => {
                return login(error)
            })
        } else {
            return login(error)
        }
    }
    return Promise.reject(error)
})

const login = (error = null) => {
    return axios.post(loginUrl, defaultUserConfig).then(res => {
        setData(res)
        error.config.headers.authorization = `Bearer ${res.data.accessToken}`
        return axios.request(error.config)
    })
}

const setData = res => {
    const tokens = {accessToken: res.data.accessToken, refreshToken: res.data.refreshToken}
    localStorage.setItem('tokens', JSON.stringify(tokens))
}

export {api}
