import moment from "moment";
import {getViewPortMonth} from "@/store/services/numberService";
import {api, baseURL} from '@/api/new_api'

export const actions = {
    async getAmenitiesList({commit, state}, params = {}) {
        if (!params?.btnLoad) commit('showAmenitiesLoader')
        const url = `${baseURL}/Amenities/Search`;
        const config = {
            params: {
                'search': state.searchValue || null,
                'propertyId': state.propertyId,
                'contactId': state.contactId,
                'buildingId': state.buildingId,
                'take': params?.take || 9,
                'skip': params?.skip || 0,
            }
        }
        try {
            const res = await api.get(url, config)
            await commit('setAmenitiesTotalCount', res.data.pagination.totalCount || 0)
            await commit('setAmenitiesList', res.data.amenityList)
        } catch (e) {
            console.log(e)
        } finally {
            if (!params?.btnLoad) commit('hideAmenitiesLoader')
        }
    },
    async getAmenitiesReservations({commit, state}, params = {}) {
        if (!params?.btnLoad) commit('showReservationLoader')
        const url = `${baseURL}/Amenities`;
        const config = {
            params: {
                'propertyId': state.propertyId,
                'contactId': state.contactId,
                'buildingId': state.buildingId,
                'take': params?.take || 9,
                'skip': params?.skip || 0,
            }
        }
        try {
            const res = await api.get(url, config)
            await commit('setAmenitiesReservations', res.data.reservationList)
            await commit('setReservationsTotalCount', res.data.pagination.totalCount || 0)
        } catch (e) {
            console.log(e)
        } finally {
            if (!params?.btnLoad) commit('hideReservationLoader')
        }
    },
    async getDetailsAmenity({commit, state}, notHotLoading = null) {
        !notHotLoading ? commit('showPageLoader') : null
        const url = `${baseURL}/Amenities/Details`;
        const config = {
            params: {
                'id': state.facilityId,
                'contactId': state.contactId,
            }
        }
        try {
            let res = await api.get(url, config)
            await commit('setDetailsAmenity', res.data)
            await commit('setFacilityTimes', res.data.facilityTimes)
            await commit('setReserveMinTime', res.data.reservMinTime || '01:00')
            await commit('setReserveMaxTime', res.data.reservMaxTime)
        } catch (e) {
            console.log(e)
        } finally {
            !notHotLoading ? commit('hidePageLoader') : null
        }
    },
    async getUnitContactDetails({commit, state}) {
        const url = `${baseURL}/Amenities/UnitContactsForAmenityDetails`;
        const config = {
            params: {
                'propertyId': state.propertyId,
                'contactId': state.contactId,
            }
        }
        try {
            let res = await api.get(url, config)
            await commit('setUnitContactDetails', res.data)
        } catch (e) {
            console.log(e)
        }
    },
    async goToPageAmenityDetails({commit}, id) {
        await commit('setFacilityId', id)
        await commit('homeAmenitiesPage', false)
        await commit('detailsAmenityPage', true)
        window.location.href = "#amenities_home_app"
    },
    async getHomePageAmenities({commit, dispatch}) {
        window.location.href = "#amenities_home_app"
        await commit('showPageLoader')
        await commit('clearAmenitiesList')
        await commit('clearAmenitiesReservations')
        await dispatch('getAmenitiesReservations')
        await dispatch('getAmenitiesList')
        await commit('clearAmenityDetails')
        await commit('homeAmenitiesPage', true)
        await commit('detailsAmenityPage', false)
        await commit('hidePageLoader')
    },
    async getDetailsReservation({commit}, item) {
        await commit('showDetailsReservationsLoader')
        let url = `${baseURL}/Amenities/AmenityReservationDetails`
        const config = {
            params: {
                'id': item.id,
            }
        }
        try {
            const res = await api.get(url, config)
            commit('setDetailsReservation', res.data)
        } catch (e) {
            commit('hideDetailsReservationModal')
            throw new Error(e)
        } finally {
            await commit('hideDetailsReservationsLoader')
        }
    },
    async deleteReservation({state}) {
        const url = `${baseURL}/Amenities/deletereservefacility`;
        const params = `?frId=${state.amenityDeleteId}`
        return await api.post(url + params)
    },
    clearDetailsReservation({commit}) {
        document.querySelector('body').style.overflow = 'auto'
        commit('setDetailsReservation', null)
    },
    async getTimeLine({state, commit}, data) {
        const url = `${baseURL}/Amenities/FacilityTimes`;
        const config = {
            params: {
                'frId': state.facilityId,
                'contactId': state.contactId,
                'date': data.id,
            },
        }
        try {
            let res = await api.get(url, config)
            res.data?.length && (moment(data.id).isAfter(moment().format()) || data.id === moment().format('YYYY-MM-DD'))
                ? await commit('isFullDayBlocked', false)
                : await commit('isFullDayBlocked', true)

            await commit('setAllTimeLine', res.data?.length ? res.data.find(el => el.id === 0) : null)
            await commit('showTimeLine')
            await commit('activateTabDay')
            await commit('deactivationTabMonth')
            if (!state.bookWindowView) commit('showBookWindow')
        } catch (e) {
            console.log(e)
        }
    },
    replaceCalendarMonth({dispatch}, date) {
        let currentDate = null
        if (date.number > 12) {
            const year = ++date.year
            const monthName = moment(new Date(year, 0)).format('MMMM')
            dispatch('getCalendarActiveMonth',
                {name: monthName, number: 1, year})
            currentDate = moment(new Date(year, 0)).format('MMMM D, YYYY')
        } else if (date.number < 1) {
            const year = --date.year
            const monthName = moment(new Date(year, 11)).format('MMMM')
            dispatch('getCalendarActiveMonth',
                {name: monthName, number: 12, year})
            currentDate = moment(new Date(year, 11)).format('MMMM D, YYYY')
        } else {
            const year = date.year
            const monthNumber = date.number
            const monthName = moment(new Date(year, date.number - 1)).format('MMMM')
            dispatch('getCalendarActiveMonth',
                {name: monthName, number: monthNumber, year})
            currentDate = moment(new Date(year, date.number - 1)).format('MMMM D, YYYY')
        }
        dispatch('getCalendarCurrentDate', currentDate)
    },
    getCalendarActiveMonth({commit, dispatch}, date) {
        dispatch('getCalendarViewPortDates', {year: date.year, month: date.number})
        commit('setActiveMonth', date)
    },
    getSelectedBookingDate({commit, dispatch}, data) {
        commit('setSelectedBookingDate', data)
        const currentDate = moment(data.id).format('MMMM D, YYYY')
        dispatch('getCalendarCurrentDate', currentDate)
    },
    getCalendarCurrentDate({commit}, date) {
        commit('setCurrentDate', date)
    },
    getCalendarViewPortDates({commit}, date) {
        commit('setCalendarViewPortDates', getViewPortMonth(date))
    },
    async bookingAmenity({state, commit}, data) {
        commit('showModalLoader')
        const url = `${baseURL}/Amenities/ReserveFacility`
        let formData = {
            "id": state.facilityId,
            "date": moment(data.date).format('MM/DD/YYYY'),
            "timeStart": moment(data.start).format('HH:mm'),
            "timeEnd": moment(data.end).format('HH:mm'),
            "unitContactId": data.unitId,
            "contactId": state.contactId,
            "attendees": data.attendees
        }

        const res = await api.post(url, formData)
        const status = {isSuccess: res.data.isSuccess, message: res.data.message}
        await commit('setStatusBooking', status)
    }
}

