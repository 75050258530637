<template src='./index.html'/>

<script>

import AmenitiesDetailsSlider from './details/amenities-details-slider-component'
import AmenitiesDetailsContent from './details/amenities-details-content-component'
import BookingModal from './details/booking-modal-component'
import SuccessModal from '@/ui/modal-confirm-component'
import Button from '@/ui/button-component'
import Loader from '@/ui/loader-component'

export default {
  name: "amenities-details-component",
  components: {AmenitiesDetailsSlider, AmenitiesDetailsContent, BookingModal, SuccessModal, Button, Loader},
  async created() {
    await this.$store.dispatch('getDetailsAmenity')
    await this.$store.dispatch('getUnitContactDetails')
  },
  methods: {
    async getDetailsAmenity(notHotLoading) {
      await this.$store.dispatch('getDetailsAmenity', notHotLoading)
      await this.$store.dispatch('getUnitContactDetails')
      this.$store.commit('hideSuccessNotification')
    },
    async returnToHomePage() {
      await this.$store.dispatch('getHomePageAmenities')
      this.$store.commit('hideSuccessNotification')
    },
  },
  computed: {
    contact() {
      return this.$store.getters.contactId
    },
    amenity() {
      return this.$store.getters.detailsAmenity
    },
    bookWindowView() {
      return this.$store.getters.bookWindowView
    },
    successNotification() {
      return this.$store.getters.successNotification
    },
    loader() {
      return this.$store.getters.pageLoader
    }
  }
}
</script>

<style module lang="scss" src='../../../../assets/styles/amenities/styles.scss'/>
