<template>
  <div :class="$style.rbs__amenities_calendar">
    <CalendarWidget />
  </div>
</template>

<script>

import CalendarWidget from './amenities-calendar-widget-component'

export default {
  name: "amenities-details-calendar-component",

  components: {CalendarWidget},
}
</script>

<style module lang="scss" src='../../../../../../assets/styles/amenities/styles.scss'/>