<template>
  <div
      :class="[$style.rbs__btn, dark_theme ? $style.rbs__btn_dark : null,
       transparent_theme ? $style.rbs__btn_transparent : null,
       disabled ? $style.rbs__btn_disabled : null]"
      :style="[height ? `height: ${height}px;` : null, gap ? `padding: 0 ${gap}px` : null]">
    <span :class="$style.rbs__btn_icon" v-if="icon">
      <img :src="icon" alt="con">
    </span>
    <span v-if="loading">
      <svg xmlns="http://www.w3.org/2000/svg"
           style="margin: auto;
           display: block;
           opacity: 0.7;
           shape-rendering: auto;"
           width="30px"
           height="30px" viewBox="0 0 100 100" preserveAspectRatio="xMidYMid">
    <circle cx="50" cy="50" fill="none" stroke="#7f7777" stroke-width="10" r="35"
            stroke-dasharray="164.93361431346415 56.97787143782138">
      <animateTransform attributeName="transform" type="rotate" repeatCount="indefinite" dur="1s"
                        values="0 50 50;360 50 50" keyTimes="0;1"></animateTransform>
    </circle>
  </svg>
    </span>
    <span v-else>
      {{ text }}
    </span>
  </div>
</template>

<script>
export default {
  name: "ui-button",
  props: {
    text: {
      type: String,
      default: 'SUBMIT'
    },
    dark_theme: {
      type: Boolean,
      default: false
    },
    transparent_theme: {
      type: Boolean,
      default: false
    },
    height: {
      type: Number,
      default: null
    },
    gap: {
      type: Number,
      default: null
    },
    icon: {
      type: String,
      default: null,
    },
    loading: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
}
</script>

<style module lang="scss" src='../assets/styles/elements/buttons.scss'/>