<template>
  <div :class="$style.rbs__amenities_details_content" v-if="contact">
    <div :class="$style.rbs__amenities_details_tabs">
      <div
          :class="[$style.rbs__amenities_details_tab, tab.isActive ? $style.rbs__amenities_details_tab_active : null ]"
          v-for="(tab, index) in tabs"
          :key="index"
          @click.prevent="changeActiveTab(index)"
      >
        {{ tab.title }}
      </div>
    </div>
    <div :class="$style.rbs__amenities_details_inner">
      <AmenitiesDetailsInfo :amenity="amenity" v-if="content === 1"/>
      <CalendarPageHead v-if="content === 2"/>
      <AmenitiesDetailsCalendar v-if="content === 2"/>
      <AmenitiesDetailsDocuments :amenity="amenity" v-if="content === 3"/>
      <div :class="$style.rbs__amenities_details_bottom">
        <Button v-if="amenity?.reservable" :class="$style.rbs__amenities_details_btn"
                @click="$store.commit('showBookWindow')"
                :text="'book now'"/>
        <div :class="$style.rbs__amenities_details_back"
             @click="$store.dispatch('getHomePageAmenities')">
          back
        </div>
      </div>
    </div>
  </div>
  <div v-else>
    <div :class="[$style.rbs__amenities_details_back, $style.rbs__amenities_details_back_nocontact]"
         style="margin: 25px auto 0;"
         @click="$store.dispatch('getHomePageAmenities')">
      back
    </div>
  </div>
</template>

<script>

import AmenitiesDetailsInfo from './amenities-details-info-component'
import AmenitiesDetailsCalendar from './calendar/amenities-calendar-component'
import CalendarPageHead from './calendar/amenities-page-calendar-head-component'
import AmenitiesDetailsDocuments from './amenities-details-documents-component'
import {currentDateDetails} from '@/utils/time_operations'

import Button from '../../../../../ui/button-component'

export default {
  name: "amenities-details-content-component",
  components: {
    AmenitiesDetailsInfo,
    AmenitiesDetailsCalendar,
    AmenitiesDetailsDocuments,
    CalendarPageHead,
    Button
  },
  data: () => ({
    content: 1,
  }),
  setup() {
    return {currentDateDetails}
  },
  created() {
    const {currentDate, monthName, monthNumber, year} = this.currentDateDetails()
    this.$store.dispatch('getCalendarActiveMonth', {name: monthName, number: monthNumber, year})
    this.$store.dispatch('getCalendarCurrentDate', currentDate)
  },
  methods: {
    changeActiveTab(index) {
      this.tabs.forEach(el => el.isActive = false);
      this.tabs[index].isActive = true;
      this.content = index + 1
    },
  },
  updated() {
    let tab = this.tabs.find(el => el.isActive)
    this.content = tab.value
  },
  computed: {
    contact() {
      return this.$store.getters.contactId
    },
    amenity() {
      return this.$store.getters.detailsAmenity
    },
    tabs() {
      if (this.amenity?.reservable) {
        return [
          {
            value: 1,
            title: 'details',
            isActive: true,
          },
          {
            value: 2,
            title: 'calendar',
            isActive: false,
          },
        ]
      } else {
        return [
          {
            value: 1,
            title: 'details',
            isActive: true,
          }
        ]
      }
    }
  }
}
</script>

<style module lang="scss" src='../../../../../assets/styles/amenities/styles.scss'/>
