import {ref} from "vue";

export const useSort = (sort = null, items = null) => {
    const sortKey = ref(null)
    const originalItems = ref(null)
    const sortedItems = ref(items?.value?.length ? items.value : items)
    const currentSort = ref(sort)

    const sortBy = (key, withReboot = false) => {
        if (!originalItems.value) setByOriginalItems(sortedItems.value)
        if (sortKey.value !== key) clearSortType(currentSort.value)
        !withReboot
            ? sortSelection(currentSort.value[key])
            : sortSelectionWithReboot(currentSort.value[key])
        sortKey.value = key
    }

    const setByOriginalItems = items => {
        originalItems.value = items
    }
    const clearSortType = sort => {
        Object.keys(sort).forEach(key => sort[key].sortType = null)
    }
    const sortSelection = item => {
        if (item.sortType) {
            switch (item.sortType) {
                case 'desc':
                    sortByAsc(item.key)
                    break;
                case 'asc':
                    sortByDesc(item.key)
                    break;
            }
        } else {
            sortByAsc(item.key)
        }
    }

    const sortSelectionWithReboot = item => {
        if (item.sortType) {
            switch (item.sortType) {
                case 'desc':
                    item.sortType = 'asc'
                    break;
                case 'asc':
                    item.sortType = 'desc'
                    break;
            }
        } else {
            item.sortType = 'asc'
        }
    }
    const sortByDesc = (key) => {
        setSortTypeByItem(currentSort.value[key], 'desc')
        sortedItems.value.sort((a, b) => {
            if (!a[key]) a[key] = ''
            if (!b[key]) b[key] = ''
            return a[key].localeCompare(b[key])
        })
    }
    const sortByAsc = (key) => {
        setSortTypeByItem(currentSort.value[key], 'asc')
        sortedItems.value.sort((a, b) => {
            if (!a[key]) a[key] = ''
            if (!b[key]) b[key] = ''
            return b[key].localeCompare(a[key])
        })
    }
    const setSortTypeByItem = (item, val) => {
        if (item)
            item.sortType = val
    }

    return {sortBy, sortedItems}
}