import { getCookie } from '@/store/services/getCookie';

let vueAPIUrl = null;
export const getWebApiBaseUrl = () => {
  if (vueAPIUrl === null) {
    vueAPIUrl = getCookie("webApiUrl")
  }
  
  return vueAPIUrl;
}
