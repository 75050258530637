<template>
  <div :class="$style.rbs__amenities_calendar_head_window">
    <div :class="$style.rbs__amenities_calendar_head_window_top">
      <div :class="[$style.rbs__amenities_calendar_head_date, $style.rbs__amenities_calendar_head_window_date]">
        {{ currentDate }}
      </div>
      <div :class="$style.rbs__amenities_calendar_head_window_tabs">
        <div
            @click="switchDay"
            :class="[$style.rbs__amenities_calendar_head_window_tab, activeTabDay ? $style.rbs__amenities_calendar_head_window_tab_active : null]">
          Day
        </div>
        <div
            @click="switchMonth"
            :class="[$style.rbs__amenities_calendar_head_window_tab, activeTabMonth ? $style.rbs__amenities_calendar_head_window_tab_active : null]"
            class="rbs-main-color">
          Month
        </div>
      </div>
    </div>
    <div :class="$style.rbs__amenities_calendar_head_window_switcher">
      <img v-if="activeTabDay" @click="prevDay" :src="'/img/widgets/icons/arrow_black_left.svg'"
           :class="[$style.rbs__amenities_calendar_arrow, $style.rbs__amenities_calendar_arrow_prev]"
           alt="arrow_black_left">
      <img v-else @click="prevMonth" :src="'/img/widgets/icons/arrow_black_left.svg'"
           :class="[$style.rbs__amenities_calendar_arrow, $style.rbs__amenities_calendar_arrow_prev]"
           alt="arrow_black_left">
      <span v-if="activeTabMonth">{{ activeMonth.name }}</span>
      <span v-if="activeTabDay">{{ activeDay }}</span>
      <img v-if="activeTabDay" @click="nextDay" :src="'/img/Widgets/icons/arrow_black_right.svg'"
           :class="[$style.rbs__amenities_calendar_arrow, $style.rbs__amenities_calendar_arrow_next]"
           alt="arrow_black_right">
      <img v-else @click="nextMonth" :src="'/img/Widgets/icons/arrow_black_right.svg'"
           :class="[$style.rbs__amenities_calendar_arrow, $style.rbs__amenities_calendar_arrow_next]"
           alt="arrow_black_right">
    </div>
  </div>
</template>

<script>

import moment from "moment";

export default {
  name: "amenities-details-calendar-component",
  methods: {
    switchDay() {
      this.$store.commit('activateTabDay')
      this.$store.commit('deactivationTabMonth')
      this.$store.commit('showTimeLine')
      if (!this.$store.getters.selectedBookingDate) this.$store.dispatch('getSelectedBookingDate', {id: moment(this.currentDate).format('YYYY-MM-DD')})
      this.$store.dispatch('getTimeLine', {id: moment(this.currentDate).format('YYYY-MM-DD')})
      this.clearTimeCash()
    },
    switchMonth() {
      this.$store.commit('activateTabMonth')
      this.$store.commit('deactivationTabDay')
      this.$store.commit('hideTimeLine')
      this.clearTimeCash()
    },
    prevDay() {
      let prevDate = moment(this.currentDate).subtract(1, 'days').format('MMMM D, YYYY')
      this.$store.dispatch('getCalendarCurrentDate', prevDate)
      this.$store.dispatch('getSelectedBookingDate', {id: moment(prevDate).format('YYYY-MM-DD')})
      this.$store.dispatch('getTimeLine', {id: moment(prevDate).format('YYYY-MM-DD')})
      this.clearTimeCash()
    },
    nextDay() {
      const nextDate = moment(this.currentDate).add(1, 'days').format('MMMM D, YYYY')
      this.$store.dispatch('getCalendarCurrentDate', nextDate)
      this.$store.dispatch('getSelectedBookingDate', {id: moment(nextDate).format('YYYY-MM-DD')})
      this.$store.dispatch('getTimeLine', {id: moment(nextDate).format('YYYY-MM-DD')})
      this.clearTimeCash()
    },
    clearTimeCash() {
      this.$store.commit('setSelectedStartTime', null)
      this.$store.commit('setSelectedEndTime', null)
    },
    setNewDate() {
      this.$store.dispatch('getSelectedBookingDate', {id: moment(this.currentDate).format('YYYY-MM-DD')})
    },
    prevMonth() {
      this.$store.dispatch('replaceCalendarMonth',
          {name: this.activeMonth.number, number: --this.activeMonth.number, year: +this.activeMonth.year,})
      this.setNewDate()
      this.clearTimeCash()
    },
    async nextMonth() {
      await this.$store.dispatch('replaceCalendarMonth',
          {name: this.activeMonth.number, number: ++this.activeMonth.number, year: +this.activeMonth.year,})
      this.setNewDate()
      this.clearTimeCash()
    },
  },
  computed: {
    activeMonth() {
      return this.$store.getters.activeMonth
    },
    currentDate() {
      return this.$store.getters.currentDate
    },
    activeTabMonth() {
      return this.$store.getters.activeTabMonth
    },
    activeTabDay() {
      return this.$store.getters.activeTabDay
    },
    activeDay() {
      return moment(this.currentDate).format('dddd')
    }
  }
}
</script>

<style module lang="scss" src='../../../../../../assets/styles/amenities/styles.scss'/>