import axios from "axios";

const allowedImagesTypes = [
    'bmp', 'ecw', 'ico', 'ilbm', 'jpeg', 'jpg', 'mrsid', 'pcx', 'png', 'psd', 'tga', 'tiff', 'jfif', 'webp', 'xbm', 'rla', 'rpf', 'pnm'
]

const allowedDocTypes = [
    'pdf', 'xlsx', 'xls', 'docx', 'doc', 'ppt', 'pptx', 'zip', 'txt', 'rtf', 'rar', 'jpg', 'jpeg', 'png',
]

const getFileImage = (image) => {
    return URL.createObjectURL(image)
}

const loadFileImage = (image) => {
    return URL.revokeObjectURL(image)
}

const getFileType = (val, type = 'type') => {
    if (val) {
        const arrFile = val.split('/')
        if (type === 'format') {
            arrFile.shift()
        } else {
            arrFile.pop()
        }
        return arrFile.join('')
    } else {
        return ''
    }
}

const imageUrlToFile = async url => {
    const res = await axios.get(url, {responseType: "blob"})
    return new File([res.data], 'new', {type: res.data.type})
}

const imageToBase64 = async (file) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);

    return await new Promise((resolve, reject) => {
        reader.onload = () => resolve(reader.result);
        reader.onerror = error => reject(error);
    });
}

const getReformatBytes = (bytes, decimals = 2) => {
    if (bytes === 0) {
        return '0';
    } else {
        var k = 1024;
        var dm = decimals < 0 ? 0 : decimals;
        var sizes = ['байт', 'kb', 'mb', 'gb', 'tb'];
        var i = Math.floor(Math.log(bytes) / Math.log(k));
        return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
    }
}

const getFileFormat = (name, file = null) => {
    if (name) {
        if (file?.type === 'text/xml') {
            return 'xml'
        }
        let format = name.split('.').slice(-1)[0]
        if (format) {
            return `${format}`
        } else return 'file'
    }
}
const getFileSize = (bytes, si = false, dp = 1) => {
    const thresh = si ? 1000 : 1024;

    if (Math.abs(bytes) < thresh) {
        return bytes + ' B';
    }

    const units = si
        ? ['kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']
        : ['KiB', 'MiB', 'GiB', 'TiB', 'PiB', 'EiB', 'ZiB', 'YiB'];
    let u = -1;
    const r = 10 ** dp;

    do {
        bytes /= thresh;
        ++u;
    } while (Math.round(Math.abs(bytes) * r) / r >= thresh && u < units.length - 1);

    return bytes.toFixed(dp) + ' ' + units[u];
}

const getProgressBarTime = (size) => {
    let sizeMb = size / 1000000
    if (sizeMb < 1) return sizeMb
    if (sizeMb > 1 && sizeMb <= 5) return size / 8000000
    if (sizeMb > 5 && sizeMb <= 10) return size / 10000000
    if (sizeMb > 5 && sizeMb <= 20) return size / 14000000
    if (sizeMb > 20 && sizeMb <= 40) return size / 18000000
    if (sizeMb > 40 && sizeMb <= 60) return size / 30000000
    if (sizeMb > 60) return size / 50000000
}

const getUploadFilesTimeout = (files) => {
    if (files.length) {
        let file = files.reduce((acc, curr) => acc.size > curr.size ? acc : curr)
        return getProgressBarTime(file.size) * 1000
    }
    return 0
}

const isImageType = (file) => {
    return allowedImagesTypes.find(el => {
        return el === getFileFormat(file?.name, file).toLowerCase()
    })
}

const isAllowedImageType = (file) => {
    const type = getFileType(file.type)
    const format = getFileFormat(file?.name, file)
    return type === 'image' && allowedImagesTypes.find(el => el === format)
}


export {
    getFileImage,
    loadFileImage,
    getFileType,
    getFileFormat,
    getFileSize,
    getProgressBarTime,
    getUploadFilesTimeout,
    isImageType,
    isAllowedImageType,
    getReformatBytes,
    imageUrlToFile,
    imageToBase64,
    allowedImagesTypes,
    allowedDocTypes
}