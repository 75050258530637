<template>
  <div :class="$style.rbs__datatable_actions"
       :style="[visible ? 'z-index: 1000' : null]"
       v-click-outside="hide"
       @click="change">
    <img :src="'/img/widgets/icons/drops.svg'"
         alt="drops">
    <div :class="$style.rbs__datatable_options"
         v-if="visible">
      <div :class="$style.rbs__datatable_option"
           @click="select(option)"
           v-for="option in options"
           :key="option">
        {{ option }}
      </div>
    </div>
  </div>
</template>

<script>

import {ref} from 'vue'

export default {
  name: "table-actions-component",
  props: {
    options: {
      type: Array,
      default: null
    }
  },
  emits: ['select'],
  setup(props, {emit}) {
    const visible = ref(false)
    const select = option => emit('select', option);
    const change = () => visible.value = !visible.value
    const hide = () => visible.value = false
    return {visible, select, change, hide}
  }
}
</script>

<style module lang="scss" src='../../assets/styles/elements/data_table.scss'/>