<div :class="[$style.rbs__amenities, $style.rbs__amenities_bg_gray]">
    <div :class="$style.rbs__container">
        <Loader :class="[$style.rbs__amenities_loader, $style.rbs__amenities_details_loader]"
                v-if="loader"/>
        <div v-else :class="$style.rbs__amenities_details">
            <div v-if="amenity?.name" :class="$style.rbs__amenities_details_title">
                {{ amenity.name }}
            </div>
            <AmenitiesDetailsSlider/>
            <AmenitiesDetailsContent/>
            <SuccessModal
                    v-if="successNotification && contact"
                    @hide="getDetailsAmenity('notHotLoading')">
                <template #title>
                    Success!
                </template>
                <template #text>
                    Reservation Request has been received, you will receive email from management once it is confirmed.
                </template>
                <template #confirm>
                    <Button @click="returnToHomePage" :text="'Close'"/>
                </template>
            </SuccessModal>
        </div>
    </div>
    <BookingModal v-if="bookWindowView"/>
</div>
