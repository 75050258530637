import moment from "moment";

const splitStringTime = time => {

    const hour = time.split(/:| /)[0]
    const minutes = time.split(/:| /)[1]
    const part = time.split(/:| /)[2] || null
    return {hour, minutes, part}
}

const addUpTime = (time, addTime) => {
    return moment(time, 'HH:mm')
        .add(addTime.hours, 'hours')
        .add(addTime.minutes, 'minutes')
        .format('HH:mm');
}

const subtractTime = (time, subtractTime) => {
    return moment(time, 'HH:mm')
        .subtract(subtractTime.hours, 'hours')
        .subtract(subtractTime.minutes, 'minutes')
        .format('HH:mm');
}

const convertDateTime12 = (dateTimeString) => {
    const formattedDateTime = moment(dateTimeString);
    return formattedDateTime.format("hh:mm A");
}

const getUTCTime = (time) => {
    let {
        hour: timeLineBeforeToHours,
        minutes: timeLineBeforeToMinutes,
        part: timeLineBeforeToPart
    } = splitStringTime(time)

    timeLineBeforeToHours = getHourIs24(timeLineBeforeToHours, timeLineBeforeToPart)

    return {hours: timeLineBeforeToHours, minutes: timeLineBeforeToMinutes}
}

const getDiffToMinutes = (timeStart, timeEnd) => {
    const diff = moment.utc(moment.duration(timeEnd) - moment.duration(timeStart)).format('HH:mm')
    return moment.duration(diff).asMinutes()
}

const diffTimes = (timeStart, timeEnd) => {
    return moment.utc(moment.duration(timeEnd) - moment.duration(timeStart)).valueOf()
}

const getHourIs24 = (hour, part) => {
    switch (part) {
        case 'AM':
            return getAMTo24Hour(hour)
        case 'PM':
            return getPMTo24Hour(hour)
    }
}

const getHourIs12 = (time) => {
    let {hour, minutes} = splitStringTime(time)
    if (+hour > 11) {
        return `${getPMTo12Hour(hour)}:${minutes} PM`
    } else {
        return `${getAMTo12Hour(hour)}:${minutes} AM`
    }
}

const getHourFrom12In24 = time => {
    let {hour, minutes, part} = splitStringTime(time)
    switch (part) {
        case 'AM':
            hour = getAMTo24Hour(hour)
            break;
        case 'PM':
            hour = getPMTo24Hour(hour)
    }
    return `${hour}:${minutes}`
}

const currentDateDetails = () => {
    const currentDate = moment().format('MMMM D, YYYY')
    const monthName = moment().format('MMMM')
    const monthNumber = +moment().format('M')
    const year = +moment().format('YYYY')

    return {currentDate, monthName, monthNumber, year}
}

const getPMTo12Hour = hour => {
    if (hour === '12') return '12'
    if (hour === '13') return '01'
    if (hour === '14') return '02'
    if (hour === '15') return '03'
    if (hour === '16') return '04'
    if (hour === '17') return '05'
    if (hour === '18') return '06'
    if (hour === '19') return '07'
    if (hour === '20') return '08'
    if (hour === '21') return '09'
    if (hour === '22') return '10'
    if (hour === '23') return '11'
}

const getAMTo12Hour = hour => {
    let h = parseInt(hour);
    if (h === 0) return '12'
    if (h === 1) return '01'
    if (h === 2) return '02'
    if (h === 3) return '03'
    if (h === 4) return '04'
    if (h === 5) return '05'
    if (h === 6) return '06'
    if (h === 7) return '07'
    if (h === 8) return '08'
    if (h === 9) return '09'
    if (h === 10) return '10'
    if (h === 11) return '11'
}


const getPMTo24Hour = hour => {
    let h = parseInt(hour);
    if (h === 12) return '12'
    if (h === 1) return '13'
    if (h === 2) return '14'
    if (h === 3) return '15'
    if (h === 4) return '16'
    if (h === 5) return '17'
    if (h === 6) return '18'
    if (h === 7) return '19'
    if (h === 8) return '20'
    if (h === 9) return '21'
    if (h === 10) return '22'
    if (h === 11) return '23'
}

const getAMTo24Hour = hour => {
    let h = parseInt(hour);
    if (h === 12) return '00'
    if (h === 1) return '01'
    if (h === 2) return '02'
    if (h === 3) return '03'
    if (h === 4) return '04'
    if (h === 5) return '05'
    if (h === 6) return '06'
    if (h === 7) return '07'
    if (h === 8) return '08'
    if (h === 9) return '09'
    if (h === 10) return '10'
    if (h === 11) return '11'
}

const reformatDate = (date, format = 'MM/DD/YYYY', pattern = null) => {
    return moment(date, pattern).format(format)
}

const isCurrentDate = (day, month, year) => {
    const currentDate = moment();
    const givenDate = moment(`${year}-${month}-${day}`, 'YYYY-MM-DD');
    return givenDate.isSame(currentDate, 'day');
}


export {
    splitStringTime,
    getHourIs12,
    getHourIs24,
    getHourFrom12In24,
    addUpTime,
    subtractTime,
    getUTCTime,
    getDiffToMinutes,
    diffTimes,
    reformatDate,
    currentDateDetails,
    convertDateTime12,
    isCurrentDate,
}