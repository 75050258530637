import {computed, ref} from "vue";

export const usePaginate = (countItemsOfPage = null, items = null, totalRecords = null) => {

    let pageNumber = ref(1)
    let perPage = ref(countItemsOfPage)
    let skipCount = ref(0)

    const totalCountItems = computed(() => {
        if (totalRecords?.value) return totalRecords.value
        else return items?.value.length
    })

    const totalPages = computed(() => {
        if (totalCountItems?.value) {
            return Math.ceil(totalCountItems.value / perPage.value)
        } else {
            return 1
        }
    })
    const paginatedItems = computed(() => {
        let from = (pageNumber.value - 1) * perPage.value
        let to = from + perPage.value
        if (items?.value) {
            return items.value.slice(from, to)
        } else {
            return []
        }
    })

    const pageFrom = computed(() => (pageNumber.value - 1) * perPage.value)
    const pageTo = computed(() => {
        let pageTo = (pageFrom.value + perPage.value)
        if (pageTo <= totalCountItems.value) {
            return pageTo
        } else {
            return totalCountItems.value
        }
    })

    const firstPage = () => {
        if (pageNumber.value) {
            pageNumber.value = 1
            skipCount.value = 0
        }
    }

    const nextPage = () => {
        if (totalPages.value > pageNumber.value) return pageNumber.value++
    }

    const skipPlus = () => {
        skipCount.value += perPage.value
    }

    const skipMinus = () => {
        skipCount.value -= perPage.value
    }

    const prevPage = () => {
        if (pageNumber.value > 1) return pageNumber.value--
    }

    return {
        firstPage,
        nextPage,
        prevPage,
        paginatedItems,
        totalCountItems,
        totalPages,
        pageFrom,
        pageTo,
        perPage,
        pageNumber,
        skipCount,
        skipPlus,
        skipMinus,
    }
}