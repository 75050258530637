<template>
  <div :class="$style.rbs__datatable_mobile_head">
    <div :class="$style.rbs__datatable_mobile_toggler"
         @click="toggleHead($event)"
         :style="['background: #cbaa5c']">
      <img data-icon-plus :src="'/img/widgets/icons/plus-white-icon.svg'"
           alt="plus-white-icon">
      <img data-icon-minus style="display: none;" :src="'/img/widgets/icons/minus-white-icon.svg'"
           alt="minus-white-icon">
    </div>
    <div :class="$style.rbs__datatable_mobile_name" data-table-name>
             <span v-if="item">
                {{ item }}
             </span>
      <span style="opacity: 0.7"
            v-else>not specified</span>
      <slot name="actions"></slot>
    </div>
  </div>
</template>

<script>

import {toggleHead} from "@/utils/accordion";

export default {
  name: "table-head-mob-component",
  props: {
    item: {
      type: [String, Number, Boolean],
      default: null,
    }
  },
  setup() {
    return {
      toggleHead,
    }
  }
}
</script>

<style module lang="scss" src='../../assets/styles/elements/data_table.scss'/>