<template>
  <div v-if="pagination"
       :class="$style.rbs__datatable_footer"
       :style="[loading ? 'opacity: 0.5; pointer-events: none' : null]">
    <div v-if="pagination.totalCountItems.value > 0"
         :class="$style.rbs__datatable_footer_paginator">
      <div :class="$style.rbs__datatable_footer_text">
        {{ pagination.pageFrom.value + 1 }}-{{ pagination.pageTo.value }} of {{ pagination.totalCountItems.value }}
      </div>
      <div :class="$style.rbs__datatable_footer_arrows">
        <div :class="$style.rbs__datatable_footer_arrow"
             :style="[pagination.pageNumber.value !== 1 ? null : 'opacity: 0.5; pointer-events: none']"
             @click="prev">
          <img :src="'/img/widgets/icons/arrow-left.svg'"
               alt="arrow-left">
        </div>
        <div :class="$style.rbs__datatable_footer_arrow"
             :style="[pagination.pageNumber.value !== pagination.totalPages.value ? null : 'opacity: 0.5; pointer-events: none']"
             @click="next">
          <img :src="'/img/widgets/icons/arrow-right.svg'"
               alt="arrow-right">
        </div>
      </div>
    </div>
  </div>
</template>

<script>


export default {
  props: {
    pagination: {
      type: [Array, Object],
      default: null
    },
    pageNumber: {
      type: [String, Number],
      default: null
    },
    loading: {
      type: [Boolean],
      default: null
    },
  },
  setup(props, {emit}) {
    const prev = () => {
      emit('prevPage')
      props.pagination.prevPage()
    }
    const next = () => {
      emit('nextPage')
      props.pagination.nextPage()
    }

    return {prev, next}
  },
}
</script>

<style module lang="scss" src='../../assets/styles/elements/data_table.scss'/>