<template>
  <td :class="[$style.rbs__datatable_text]">
    <span v-if="item">
      <span>
        <slot name="icon"></slot>
      </span>
      {{ item }}
    </span>
    <span style="opacity: 0.7"
          v-else>not specified</span>
    <slot name="actions"></slot>
  </td>
</template>

<script>
export default {
  name: "table-body-item-component",
  props: ['item']
}
</script>

<style module lang="scss" src='../../assets/styles/elements/data_table.scss'/>