<template>
  <div :class="$style.rbs__amenities_calendar_head_page">
    <div :class="$style.rbs__amenities_calendar_head_date">
      {{ currentDate }}
    </div>
    <div :class="$style.rbs__amenities_calendar_head_page_switcher">
      <img @click="prevMonth" :src="'/img/widgets/icons/switcher_arrow_left.svg'"
           :class="[$style.rbs__amenities_calendar_arrow, $style.rbs__amenities_calendar_arrow_prev]"
           alt="switcher-arrow-left">
      <span>{{ activeMonth.name }}</span>
      <img @click="nextMonth" :src="'/img/Widgets/icons/switcher_arrow_right.svg'"
           :class="[$style.rbs__amenities_calendar_arrow, $style.rbs__amenities_calendar_arrow_next]"
           alt="switcher-arrow-right">
    </div>
  </div>
</template>

<script>

export default {
  name: "amenities-details-calendar-component",
  data: () => ({}),
  methods: {
    prevMonth() {
      this.$store.dispatch('replaceCalendarMonth',
          {name: this.activeMonth.number, number: --this.activeMonth.number, year: +this.activeMonth.year,})
    },
    nextMonth() {
      this.$store.dispatch('replaceCalendarMonth',
          {name: this.activeMonth.number, number: ++this.activeMonth.number, year: +this.activeMonth.year,})
    },
  },
  computed: {
    activeMonth() {
      return this.$store.getters.activeMonth
    },
    currentDate() {
      return this.$store.getters.currentDate
    },
  }
}
</script>

<style module lang="scss" src='../../../../../../assets/styles/amenities/styles.scss'/>