import {
    addUpTime,
    diffTimes,
    getHourFrom12In24,
    getHourIs12,
    splitStringTime,
    subtractTime
} from "@/utils/time_operations";
import {useStore} from "vuex"

export const useTimelines = (calendarStartTime = null, calendarEndTime = null) => {
    let store = useStore();

    let timeStart = null
    let timeEnd = null

    if (calendarStartTime && calendarEndTime) {
        timeStart = getHourFrom12In24(calendarStartTime)
        timeEnd = getHourFrom12In24(calendarEndTime)
    } else {
        timeStart = getHourFrom12In24(store.getters.timelineDetails?.from || '10:00 AM')
        timeEnd = getHourFrom12In24(store.getters.timelineDetails?.to || '06:00 PM')
    }

    let reserveMinTime = getHourFrom12In24(store.getters.reserveMinTime)
    let timeline = [timeStart]

    let {hour: reserveHours, minutes: reserveMinutes} = splitStringTime(reserveMinTime)
    let extraTime = {hours: reserveHours, minutes: reserveMinutes}

    do {
        timeStart = addUpTime(timeStart, extraTime)
        if (diffTimes(timeStart, timeEnd) >= 0)
            timeline.push(timeStart)
    } while (diffTimes(timeStart, timeEnd) > 0);

    let start = timeline[0]
    let end = timeline[timeline.length - 1]

    for (let i = 1; i < 3; i++) {
        start = subtractTime(start, extraTime)
        timeline.unshift(start)
    }

    for (let i = 1; i < 3; i++) {
        end = addUpTime(end, extraTime)
        timeline.push(end)
    }

    timeline = timeline.map(time => getHourIs12(time))

    return timeline
}

