export const buildQueryParams = params => {
    let query = '?'
    let keys = Object.keys(params)
    if (keys.length) {
        keys.forEach((key, idx) => {
            if (idx !== 0) query += '&'
            query += `${key}=${params[key]}`
        })
    }
    return query
}