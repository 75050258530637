<template>
  <div :class="$style.rbs__modal">
    <div :class="$style.rbs__overlay" @click="$emit('hideModal')"></div>
    <div :class="[$style.rbs__modal_page, ...styleClasses]"
         :style="[
             minHeight ? `min-height: ${minHeight}`: null,
             maxWidth ? `max-width: ${maxWidth}`: null,
             height ? `height: ${height}`: null,
             width ? `width: ${width}` : null]">
      <div :class="$style.rbs__modal_loader" v-if="loader">
        <Loader/>
      </div>
      <img src="/img/widgets/icons/times-white.svg"
           alt="times-white.svg"
           :class="$style.rbs__modal_close">
      <div :class="$style.rbs__modal_page_title">
        <slot name="title"></slot>
      </div>
      <div :class="[$style.rbs__modal_page_content, ...contentStyleClasses]">
        <slot name="content"></slot>
      </div>
      <div :class="[$style.rbs__modal_confirm_actions, ...actionStyleClasses]">
        <slot name="actions"></slot>
      </div>
    </div>
  </div>
</template>

<script>

import Loader from "@/ui/loader-component";

export default {
  name: "modal-index-component",
  components: {Loader},
  props: {
    minHeight: {
      type: String,
      default: null
    },
    maxWidth: {
      type: String,
      default: null
    },
    width: {
      type: String,
      default: null
    },
    height: {
      type: String,
      default: null
    },
    close: {
      type: Boolean,
      default: false
    },
    loader: {
      type: Boolean,
      default: false
    },
    noHidden: {
      type: Boolean,
      default: false
    },
    styleClasses: {
      type: Array,
      default: Array.of([])
    },
    contentStyleClasses: {
      type: Array,
      default: Array.of([])
    },
    actionStyleClasses: {
      type: Array,
      default: Array.of([])
    },
  },
  mounted() {
    document.querySelector('body').style.overflow = 'hidden'
  },
  beforeUnmount() {
    if (!this.noHidden)
      document.querySelector('body').style.overflow = 'auto'
  }
}
</script>

<style module lang="scss" src='../assets/styles/elements/modals.scss'/>/>