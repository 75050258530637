import {createStore} from 'vuex'
import Amenities from './modules/amenities'


const store = createStore({
    modules: {
        Amenities
    }
})

export default store
