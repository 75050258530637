<template>
  <div :class="$style.rbs__amenities_details_slider" v-if="amenityImages?.length">
    <swiper
        :navigation="!responseEdition ? {prevEl: prev,nextEl: next} : false"
        :slidesPerView="!responseEdition ? 3 : 1"
        :spaceBetween="20"
        :slidesPerGroup="1"
        :lazy="true"
        :pagination="responseEdition && amenityImages.length > 1 ? {clickable: true} : false"
        :loop="false"
        :loopFillGroupWithBlank="true"
        :modules="modules"
        class="mySwiper w-1/4"
    >
      <swiper-slide :class="$style.rbs__amenities_details_slide"
                    v-for="image in amenityImages"
                    :key="image">
        <photo-provider>
          <photo-consumer style="width: 100%;"
                          :key="`/photoGallery/imagethumb/${image}`" :src="`/photoGallery/imagethumb/${image}`">
            <img :src="`/photoGallery/imagethumb/${image}`"
                 class="swiper-lazy"
                 :class="$style.rbs__amenities_details_slider_img"
                 alt="default">
          </photo-consumer>
        </photo-provider>
      </swiper-slide>
    </swiper>
    <div :class="$style.rbs__amenities_details_slider_btns" v-if="amenityImages.length > 3">
      <div ref="prev"
           :class="[$style.rbs__amenities_details_slider_btn, $style.rbs__amenities_details_slider_btn_prev]">
        <svg width="14" height="24" viewBox="0 0 14 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M12 22L2 12L12 2" stroke="white" stroke-width="3.34615" stroke-linecap="round"
                stroke-linejoin="round"/>
        </svg>
      </div>
      <div ref="next"
           :class="[$style.rbs__amenities_details_slider_btn, $style.rbs__amenities_details_slider_btn_next]">
        <svg width="14" height="24" viewBox="0 0 14 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M2 2L12 12L2 22" stroke="white" stroke-width="3.34615" stroke-linecap="round"
                stroke-linejoin="round"/>
        </svg>
      </div>
    </div>
  </div>
</template>

<script>

import {Swiper, SwiperSlide} from 'swiper/vue';
import SwiperCore, {Lazy, Navigation, Pagination} from "swiper";
import {ref} from 'vue';

SwiperCore.use([Pagination, Navigation]);
export default {
  components: {
    Swiper,
    SwiperSlide,
  },
  setup() {
    const prev = ref(null);
    const next = ref(null);
    return {
      modules: [Navigation, Lazy],
      prev,
      next,
    };
  },
  mounted() {
    this.resizeWindow()
    window.addEventListener('resize', this.resizeWindow)
  },
  methods: {
    resizeWindow() {
      this.$store.commit('responseEdition', window.innerWidth < this.responseEditionWidth)
    },
  },
  computed: {
    responseEdition() {
      return this.$store.getters.responseEdition
    },
    responseEditionWidth() {
      return this.$store.getters.responseEditionWidth
    },
    amenityImages() {
      let ids = this.$store.getters.detailsAmenity.imageIds
      let images = []
      ids.forEach(el => {
        let result = false
        let img = new Image();
        img.src = `photogallery/imagethumb/${el}`;
        img.addEventListener('load', () => result = true);
        if (result) images.push(`photogallery/imagethumb/${el}`)
      })
      return images
    }
  }
};
</script>

<style module lang="scss" src='../../../../../assets/styles/amenities/styles.scss'/>