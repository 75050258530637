<template>
  <div :class="$style.rbs__amenities_details_info" v-if="amenity">
    <div :class="$style.rbs__amenities_details_section">
      <div :class="$style.rbs__amenities_details_section_item">
        <div :class="$style.rbs__amenities_details_section_title">description</div>
        <div :class="$style.rbs__amenities_details_section_text">
          <span v-if="amenity?.description" v-html="amenity.description"></span>
          <span v-else :class="$style.rbs__amenities_details_section_text_empty">no data</span>
        </div>
      </div>
      <div :class="$style.rbs__amenities_details_section_item">
        <div :class="$style.rbs__amenities_details_section_title">reservation limit</div>
        <div :class="$style.rbs__amenities_details_section_text">
          <span v-if="amenity?.capacity">{{ amenity.capacity }}</span>
          <span v-else :class="$style.rbs__amenities_details_section_text_empty">no data</span>
        </div>
      </div>
    </div>
    <div :class="$style.rbs__amenities_details_section">
      <div :class="$style.rbs__amenities_details_section_item">
        <div :class="$style.rbs__amenities_details_section_title">Hours</div>
        <div v-if="workingHours?.length"
             :class="$style.rbs__amenities_details_section_workinghours">
          <div :class="$style.rbs__amenities_details_section_workinghours_item"
               v-for="item in workingHours" :key="item.day">
            <div :class="$style.rbs__amenities_details_section_day">{{ item.day }}</div>
            <div :class="$style.rbs__amenities_details_section_hours">{{ item.start_time }} - {{ item.end_time }}</div>
          </div>
        </div>
        <span v-else :class="$style.rbs__amenities_details_section_text_empty">no data</span>
      </div>
    </div>
    <div v-if="amenity?.reservable" :class="$style.rbs__amenities_details_section">
      <div v-if="amenity?.reservable" :class="$style.rbs__amenities_details_section_item">
        <div :class="$style.rbs__amenities_details_section_title">deposit amount</div>
        <div :class="$style.rbs__amenities_details_section_text">
          <span v-if="amenity?.depositAmount">${{ amenity.depositAmount }}</span>
          <span v-else :class="$style.rbs__amenities_details_section_text_empty">no data</span>
        </div>
      </div>
      <div v-if="amenity?.reservable" :class="$style.rbs__amenities_details_section_item">
        <div :class="$style.rbs__amenities_details_section_title">payment type</div>
        <div :class="$style.rbs__amenities_details_section_text">
          <span v-if="amenity?.paymentType">{{ amenity.paymentType }}</span>
          <span v-else :class="$style.rbs__amenities_details_section_text_empty">no data</span>
        </div>
      </div>
      <div v-if="amenity?.reservable" :class="$style.rbs__amenities_details_section_item">
        <div :class="$style.rbs__amenities_details_section_title">price</div>
        <div :class="$style.rbs__amenities_details_section_text">
          <span v-if="amenity?.price">${{ amenity.price }}</span>
          <span v-else :class="$style.rbs__amenities_details_section_text_empty">no data</span>
        </div>
      </div>
    </div>
    <div :class="$style.rbs__amenities_details_section"
         v-if="amenity.documents?.length">
      <div :class="$style.rbs__amenities_details_section_item">
        <div :class="$style.rbs__amenities_details_section_title">documents</div>
        <div :class="$style.rbs__amenities_details_section_documents">
          <a :href="`/api/file/${document.id}`"
             :class="$style.rbs__amenities_details_section_document"
             v-for="document in amenity.documents"
             :key="document.id">
            <div :class="$style.rbs__amenities_details_section_document_icon">
              <img :src="'/img/widgets/icons/pdf_file_icon.svg'"
                   alt="pdf_file_icon">
              <span v-if="document.extension"
                    :class="$style.rbs__amenities_details_section_document_format">
                {{ getFileFormat(document.extension) }}
              </span>
              <span v-else :class="$style.rbs__amenities_details_section_document_format">
                file
              </span>
            </div>
            <div :class="$style.rbs__amenities_details_section_document_description">
              <div :class="$style.rbs__amenities_details_section_document_title">
                <span v-if="document.name">{{ document.name }}</span>
                <span v-else>File</span>
              </div>
              <div :class="$style.rbs__amenities_details_section_document_text">
                {{ getReformatBytes(document.size) }}
              </div>
            </div>
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import {getFileFormat, getReformatBytes} from '@/utils/files_operations'

export default {
  name: "amenities-details-info-component",
  props: ['amenity'],
  data: () => ({
    days: ['sunday ', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday']
  }),
  setup() {
    return {getFileFormat, getReformatBytes}
  },
  computed: {
    workingHours() {
      let result = []
      this.amenity?.workingHours.forEach((el, i) => {
        if (el.startTime) {
          result.push({
            day: this.days[i],
            start_time: el.startTime,
            end_time: el.endTime
          })
        }
      })
      return result.filter(el => el.start_time && el.end_time)
    }
  }
}
</script>

<style module lang="scss" src='../../../../../assets/styles/amenities/styles.scss'/>