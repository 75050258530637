<template>
  <div :class="$style.rbs__modal">
    <div :class="$style.rbs__overlay" @click="$emit('hide')"></div>
    <div :class="$style.rbs__modal_confirm">
      <div :class="$style.rbs__modal_confirm_title">
        <slot name="title"></slot>
      </div>
      <div :class="$style.rbs__modal_confirm_text">
        <slot name="text"></slot>
      </div>
      <div :class="$style.rbs__modal_confirm_actions">
        <slot name="cancel"></slot>
        <slot name="confirm"></slot>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "modal-confirm-component",
  mounted() {
    document.querySelector('body').style.overflow = 'hidden'
  },
  beforeUnmount() {
    document.querySelector('body').style.overflow = 'auto'
  }
}
</script>

<style module lang="scss" src='../assets/styles/elements/modals.scss'/>/>