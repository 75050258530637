export function toggleHead(e) {
    const item = e.target.closest('[data-table]')
    const body = item.querySelector('[data-body]')
    const name = item.querySelectorAll('[data-table-name]')
    const bodyBorder = item.querySelector('[data-body-border]')
    if (item.classList.contains('open')) {
        hideContent(item, body, bodyBorder, name)
    } else {
        showContent(item, body, bodyBorder, name)
    }
}

export function toggleAcc(e) {
    const item = e.target.closest('[data-acc]')
    const body = item.querySelector('[data-acc-body]')
    const name = item.querySelectorAll('[data-acc-name]')
    const bodyBorder = item.querySelector('[data-acc-border]')
    if (item.classList.contains('open')) {
        hideContent(item, body, bodyBorder, name)
    } else {
        showContent(item, body, bodyBorder, name)
    }
}

export function showAcc(e) {
    const item = e.target.closest('[data-acc]')
    const body = item.querySelector('[data-acc-body]')
    const name = item.querySelectorAll('[data-acc-name]')
    const bodyBorder = item.querySelector('[data-acc-border]')
    showContent(item, body, bodyBorder, name)
}

export function resetAccordion() {
    document.querySelectorAll('[data-table]').forEach(el => {
        el.classList.remove('open')
        el.style.background = '#ffffff'
    })
    document.querySelectorAll('[data-icon-plus]').forEach(el => el.style.display = 'block')
    document.querySelectorAll('[data-icon-minus]').forEach(el => el.style.display = 'none')
    document.querySelectorAll('[data-table-name]').forEach(el => el.style.fontWeight = `400`)
    document.querySelectorAll('[data-body-border]').forEach(el => el.style.height = `0`)
    document.querySelectorAll('[data-body]').forEach(el => el.style.maxHeight = `0`)
}

export function showAccordion() {
    document.querySelectorAll('[data-table]').forEach(el => {
        el.classList.add('open')
        el.style.background = '#F4F6F8'
    })
    document.querySelectorAll('[data-icon-plus]').forEach(el => el.style.display = 'none')
    document.querySelectorAll('[data-icon-minus]').forEach(el => el.style.display = 'block')
    document.querySelectorAll('[data-table-name]').forEach(el => el.style.fontWeight = `600`)
    document.querySelectorAll('[data-body-border]').forEach(el => {
        let parent = el.closest('[data-body]')
        el.style.height = parent.scrollHeight + "px"
    })
    document.querySelectorAll('[data-body]').forEach(el => {
        el.style.maxHeight = el.scrollHeight + "px"
    })
}

export function resetAccordionFromOnlyToggle() {
    document.querySelectorAll('[data-acc]')
        .forEach(el => el.classList.remove('open'))
    document.querySelectorAll('[data-acc-name]').forEach(el => el.style.fontWeight = `400`)
    document.querySelectorAll('[data-acc-border]').forEach(el => el.style.height = `0`)
    document.querySelectorAll('[data-acc-body]').forEach(el => el.style.maxHeight = `0`)
}

function hideContent(item, body, bodyBorder, name) {
    item.classList.remove('open')
    name ? name.forEach(el => el.style.fontWeight = `400`) : null
    if (item.querySelector('[data-icon-plus]')) item.querySelector('[data-icon-plus]').style.display = 'block'
    if (item.querySelector('[data-icon-minus]')) item.querySelector('[data-icon-minus]').style.display = 'none'
    if (body) body.style.maxHeight = `0`
    if (bodyBorder) bodyBorder.style.height = `0`
    if (item) item.style.background = '#ffffff'
}

function showContent(item, body, bodyBorder, name) {
    item.classList.add('open')
    name ? name.forEach(el => el.style.fontWeight = `600`) : null
    if (item.querySelector('[data-icon-plus]')) item.querySelector('[data-icon-plus]').style.display = 'none'
    if (item.querySelector('[data-icon-minus]')) item.querySelector('[data-icon-minus]').style.display = 'block'
    if (body) body.style.maxHeight = body.scrollHeight + "px";
    if (bodyBorder) bodyBorder.style.height = body.scrollHeight + "px";
    if (item) item.style.background = '#F4F6F8'
}


