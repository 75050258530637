import moment from "moment";

const COUNT_VIEWPORT_CELLS = 42

const getCountDaysFromPrevMonth = (dayNumber) => {
    if (+dayNumber) {
        return dayNumber - 1
    } else return 6
}

const getDays = (year, month) => {
    let currentDate = new Date(year, month, 0).getDate()
    return new Array(currentDate).fill(1).map((_, index) => {
        return new Date(year, month - 1, index + 1)
    })
}

const getMonth = (customDate, inputDate) => {
    let result = []
    getDays(customDate.year, customDate.month).forEach(el => {
        result.push({
            id: moment(el).format('YYYY-MM-DD'),
            dayWeek: moment(el).format('dd'),
            dayNumber: +moment(el).format('d'),
            date: +moment(el).format('D'),
            year: +moment(el).format('YYYY'),
            month: +moment(el).format('M'),
            currentDate: moment(el).format('DD-MM-YYYY') === moment().format('DD-MM-YYYY'),
            currentMonth: JSON.stringify(customDate) === JSON.stringify(inputDate),
        })
    })
    return result
}

export const getViewPortMonth = (date) => {
    const MONTH = +date.month
    const YEAR = +date.year

    const prevDate = {year: MONTH > 1 ? YEAR : YEAR - 1, month: MONTH > 1 ? MONTH - 1 : 12}
    const currentDate = {year: YEAR, month: MONTH}
    const nextDate = {year: MONTH === 12 ? YEAR + 1 : YEAR, month: MONTH === 12 ? 1 : MONTH + 1}

    const currentMonth = getMonth(currentDate, date)
    const countPrevDaysMonth = getCountDaysFromPrevMonth(currentMonth[0]['dayNumber'])
    const countNextDaysMonth = COUNT_VIEWPORT_CELLS - countPrevDaysMonth - currentMonth.length

    const prevMonth = countPrevDaysMonth ? getMonth(prevDate, date).slice(-countPrevDaysMonth) : []
    const nextMonth = countNextDaysMonth ? getMonth(nextDate, date).slice(0, countNextDaysMonth) : []

    return [...prevMonth, ...currentMonth, ...nextMonth]
}