<template>
  <div :class="$style.rbs__datatable_mobile_body_item">
    <div :class="$style.rbs__datatable_mobile_label" v-html="title"></div>
    <div :class="$style.rbs__datatable_mobile_text">
      <span v-if="item">
        <span>
          <slot name="icon"></slot>
        </span>
        <span v-html="item"></span>
      </span>
      <span style="opacity: 0.7"
            v-else>not specified</span>
    </div>
  </div>
</template>

<script>
export default {
  name: "table-body-item-component",
  props: {
    item: {
      type: [String, Number, Boolean],
      default: null,
    },
    title: {
      type: String,
      default: 'Title'
    }
  }
}
</script>

<style module lang="scss" src='../../assets/styles/elements/data_table.scss'/>