<template>
  <div :class="$style.rbs__dropdown"
       :style="[
           height ? `height: ${height};` : null,
           width ? `width: ${width}` : null,
           minWidth ? `min-width: ${minWidth}` : null]"
       data-dropdown
       v-click-outside="hideDropdown"
       @click="changeDropdown">
    <div :class="[$style.rbs__dropdown_parent]"
         :style="[rounded ? 'border-radius: 3px' : null, padding ? `padding: ${padding}` : null]">
					<span v-if="selectedItem"
                :class="$style.rbs__dropdown_label"
                :style="[font ? `font-size: ${font}` : null]">
						{{ selectedItem }}
					</span>
      <span v-else-if="beforeSelectedItem"
            :class="$style.rbs__dropdown_label"
            :style="[font ? `font-size: ${font}` : null]">
						{{ beforeSelectedItem }}
      </span>
      <span v-else-if="placeholder"
            :class="$style.rbs__dropdown_empty"
            :style="[font ? `font-size: ${font}` : null]">
						{{ placeholder }}
      </span>
      <div @click.stop="clearField"
           v-if="(selectedItem || beforeSelectedItem) && clearMode"
           :class="[!dataMode ? $style.rbs__dropdown_clear : $style.rbs__dropdown_dateclear]">
        <img :src="'/img/widgets/icons/times.svg'" alt="times">
      </div>
      <div v-if="!dataMode" :class="$style.rbs__dropdown_arrowicon">
        <img :src="'/img/widgets/icons/chevron-down.svg'"
             :style="[visible ? 'transform: translate(0, 0) rotate(180deg)' : null]"
             alt="chevron-down">
      </div>
      <div v-else :class="$style.rbs__dropdown_calendar">
        <img :src="'/img/widgets/icons/calendar.svg'"
             alt="calendar">
      </div>
    </div>
    <div @click.stop.prevent v-if="searchMode && visible" :class="$style.rbs__dropdown_search">
      <input @click.stop.prevent type="text" :class="$style.rbs__dropdown_search_input"
             v-model="searchValue">
      <button v-if="searchValue" :class="$style.rbs__dropdown_search_clear"
              @click="searchValue=''">
        Clear
      </button>
    </div>
    <div :class="$style.rbs__dropdown_items"
         :style="searchMode ? 'top: 176%' : null"
         v-if="filteredItems?.length && visible">
      <div :class="$style.rbs__dropdown_item"
           @click="selectDropdownItem(item, $event)"
           v-for="item in filteredItems" :key="item">
        <span v-if="item.name">{{ item.name }}</span>
        <span v-else-if="item.Text">{{ item.Text }}</span>
        <span v-else-if="item.text">{{ item.text }}</span>
        <span v-else-if="item.value">{{ item.value }}</span>
        <span v-else>{{ item }}</span>
      </div>
    </div>
    <div :class="$style.rbs__dropdown_items"
         :style="searchMode ? 'top: 176%' : null"
         v-else-if="visible && !filteredItems?.length">
      <div :class="$style.rbs__dropdown_not_items">
        Empty
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "select-component",
  props: {
    items: {
      type: [Object, Array],
      default: null
    },
    beforeSelectedItem: {
      type: [Number, String],
      default: null,
    },
    placeholder: {
      type: String,
      default: 'Select Item',
    },
    height: {
      type: String,
      default: null
    },
    width: {
      type: String,
      default: null
    },
    minWidth: {
      type: String,
      default: null
    },
    rounded: {
      type: Boolean,
      default: null
    },
    font: {
      type: String,
      default: null
    },
    padding: {
      type: String,
      default: null
    },
    dataMode: {
      type: Boolean,
      default: null
    },
    clearMode: {
      type: Boolean,
      default: true,
    },
    searchMode: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    selectedItem: null,
    visible: false,
    searchValue: ''
  }),
  methods: {
    selectDropdownItem(item, e) {
      this.$emit('select', item)
      this.$emit('clearBeforeSelect')
      this.searchValue = ''
      this.selectedItem = e.target.innerText
    },
    changeDropdown() {
      this.visible = !this.visible
    },
    clearField() {
      this.selectedItem = null
      this.hideDropdown()
      this.$emit('clear')
    },
    hideDropdown() {
      this.visible = false
    },
  },
  computed: {
    filteredItems() {
      if (this.searchValue) {

        return this.items.filter(item => {
          if (item.name) {
            return item.name.toLowerCase().includes(this.searchValue.toLowerCase())
          }
          if (item.Text) {
            return item.Text.toLowerCase().includes(this.searchValue.toLowerCase())
          }
          if (item.text) {
            return item.text.toLowerCase().includes(this.searchValue.toLowerCase())
          }
          if (item) {
            return item.toLowerCase().includes(this.searchValue.toLowerCase())
          }
        })
      } else {
        return this.items
      }
    }
  }
}
</script>

<style module lang="scss" src='../assets/styles/elements/dropdown.scss'/>
