// import moment from "moment";

export const mutations = {
    setAmenitiesList(state, amenities) {
        if (state.amenities?.length) {
            state.amenities = [...state.amenities, ...amenities]
        } else {
            state.amenities = amenities
        }
    },
    setAmenitiesTotalCount(state, count) {
        state.amenitiesTotalCount = count
    },
    setReservationsTotalCount(state, count) {
        state.reservationsTotalCount = count
    },
    clearAmenitiesList(state) {
        state.amenities = []
    },
    clearAmenityDetails(state) {
        state.selectedAmenityDetails = null
    },
    setAmenitiesReservations(state, reservations) {
        if (state.reservations?.length) {
            state.reservations = [...state.reservations, ...reservations]
        } else {
            state.reservations = reservations
        }
    },
    clearAmenitiesReservations(state) {
        state.reservations = null
    },
    setDetailsAmenity(state, amenity) {
        state.selectedAmenityDetails = amenity
    },
    setFacilityTimes(state, facilityTimes) {
        state.facilityTimes = facilityTimes
    },
    isFullDayBlocked(state, bool) {
        state.isFullDayBlocked = bool
    },
    setUnitContactDetails(state, unitContact) {
        state.unitContactDetails = unitContact
    },
    setDetailsReservation(state, reservation) {
        state.selectedReservationDetails = reservation
    },
    setActiveMonth(state, monthName) {
        state.activeMonth = monthName
    },
    setCurrentDate(state, date) {
        state.currentDate = date
    },
    setCalendarViewPortDates(state, dates) {
        state.viewPortDates = []
        state.viewPortDates = dates
    },
    showBookWindow(state) {
        state.bookWindowView = true
    },
    showDetailsReservationModal(state) {
        state.reservationsDetailsModal = true
    },
    hideDetailsReservationModal(state) {
        state.reservationsDetailsModal = false
        document.querySelector('body').style.overflow = 'auto'
    },
    hideBookWindow(state) {
        document.querySelector('body').style.overflow = 'auto'
        state.bookWindowView = false
    },
    showDeleteReservation(state, id) {
        state.windowDeleteReservation = true
        state.amenityDeleteId = id
    },
    hideDeleteReservation(state) {
        state.windowDeleteReservation = false
    },
    setSelectedBookingDate(state, item) {
        state.selectedBookingDate = item
    },
    setAllTimeLine(state, timeline) {
        state.timelineDetails = timeline
    },
    showTimeLine(state) {
        state.timelineView = true
    },
    hideTimeLine(state) {
        state.timelineView = false
    },
    activateTabDay(state) {
        state.activeTabDay = true
    },
    activateTabMonth(state) {
        state.activeTabMonth = true
    },
    deactivationTabDay(state) {
        state.activeTabDay = false
    },
    deactivationTabMonth(state) {
        state.activeTabMonth = false
    },
    responseEdition(state, bool) {
        state.responseEdition = bool
    },
    setSearchValue(state, value) {
        state.searchValue = value
    },
    setBuildingId(state, id) {
        state.buildingId = id
    },
    showPageLoader(state) {
        state.pageLoader = true
    },
    hidePageLoader(state) {
        state.pageLoader = false
    },
    showAmenitiesLoader(state) {
        state.amenitiesLoader = true
    },
    hideAmenitiesLoader(state) {
        state.amenitiesLoader = false
    },
    showReservationLoader(state) {
        state.reservationLoader = true
    },
    hideReservationLoader(state) {
        state.reservationLoader = false
    },
    showModalLoader(state) {
        state.modalLoader = true
    },
    hideModalLoader(state) {
        state.modalLoader = false
    },
    setReserveMinTime(state, time) {
        state.reserveMinTime = time
    },
    setReserveMaxTime(state, time) {
        state.reserveMaxTime = time
    },
    setSelectedStartTime(state, time) {
        state.selectedStartTime = time
    },
    setSelectedEndTime(state, time) {
        state.selectedEndTime = time
    },
    clearOverAllTime(state) {
        state.timelineDetails = null
        state.selectedStartTime = null
        state.selectedEndTime = null
        state.actualTimeLine = null
        state.timelineView = false
        state.activeTabDay = false
        state.activeTabMonth = true

    },
    setStatusBooking(state, status) {
        state.statusBooking = status
    },
    clearOldStatusBooking(state) {
        state.statusBooking = {}
    },
    showSuccessNotification(state) {
        state.successNotification = true
    },
    hideSuccessNotification(state) {
        state.successNotification = false
    },
    homeAmenitiesPage(state, bool) {
        state.homeAmenitiesPage = bool
    },
    detailsAmenityPage(state, bool) {
        state.detailsAmenityPage = bool
    },
    setFacilityId(state, id) {
        state.facilityId = id
    },
    setActualTimeLines(state, timeline) {
        state.actualTimeLine = timeline
    },
    showDetailsReservationsLoader(state) {
        state.detailsReservationLoader = true
    },
    hideDetailsReservationsLoader(state) {
        state.detailsReservationLoader = false
    },
}

