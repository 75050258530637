import {getCookie} from "@/store/services/getCookie";

export const state = {
    amenities: [],
    amenitiesTotalCount: null,
    reservations: [],
    reservationsTotalCount: null,
    selectedAmenityDetails: null,
    facilityTimes: null,
    isFullDayBlocked: false,
    unitContactDetails: null,
    selectedReservationDetails: null,
    propertyId: getCookie('propertyId'),
    contactId: getCookie('contactId'),
    buildingId: getCookie('buildingId'),
    facilityId: window.amenitiesWidget?.facilityId || null,
    widgetPageUrl: window.amenitiesWidget?.widgetPageUrl || '/',
    diffTimezone: window.amenitiesWidget?.diffTimezone || null,
    buildingList: window.amenitiesWidget?.buildingList || [],
    totalRecords: window.amenitiesWidget?.totalRecord || 0,
    activeMonth: null,
    currentDate: null,
    viewPortDates: null,
    bookWindowView: false,
    windowDeleteReservation: null,
    amenityDeleteId: null,
    selectedBookingDate: null,
    timelineDetails: null,
    timelineView: false,
    activeTabDay: false,
    activeTabMonth: true,
    responseEdition: false,
    responseEditionWidth: 962,
    searchValue: null,
    pageLoader: false,
    amenitiesLoader: false,
    modalLoader: false,
    reservationLoader: false,
    reserveMinTime: null,
    reserveMaxTime: null,
    selectedStartTime: null,
    selectedEndTime: null,
    statusBooking: {},
    successNotification: false,
    homeAmenitiesPage: true,
    detailsAmenityPage: false,
    actualTimeLine: null,
    reservationsDetailsModal: false,
    detailsReservationLoader: false,
}
