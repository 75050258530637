<template>
  <div :class="$style.rbs__amenities_calendar_timeline">
    <div :class="$style.rbs__amenities_calendar_timeline_row"
         @click="selectTime(timeline)"
         :style="[getColorFromStatus(timeline.status)]"
         v-for="timeline in actualTimeLines"
         :key="timeline.time">
      {{ timeline.time }}
    </div>
  </div>
</template>

<script>

import moment from "moment";
import {addUpTime, getDiffToMinutes, getHourIs24, getUTCTime, splitStringTime,} from '@/utils/time_operations'
import {useTimelines} from '@/composition/widgets/amenities/useTimeLines.js'
import {computed} from 'vue'

export default {
  name: "amenities-calendar-timeline-component",
  data: () => ({
    customTimeLine: true,
  }),

  setup() {
    const timelines = computed(() => useTimelines())
    return {
      timelines,
      getHourIs24,
      splitStringTime,
      addUpTime,
      getUTCTime,
      getDiffToMinutes,
    }
  },
  methods: {
    getStatus(timeline, time) {
      if (this.isFullDayBlocked) {
        return 'BLOCKED'
      } else {
        const idxCurrentTime = timeline.indexOf(time)
        const idxTimeFrom = timeline.indexOf(this.timelineFrom)
        const idxTimeTo = timeline.indexOf(this.timelineTo)
        const timeLineBeforeTo = timeline[timeline.indexOf(this.timelineTo) - 1]

        const utcTimeLineBeforeTo = this.getUTCTime(timeLineBeforeTo)
        const utcTimeLineTo = this.getUTCTime(this.timelineDetails.To)
        const diffAsMinutes = getDiffToMinutes(utcTimeLineBeforeTo, utcTimeLineTo)

        const timeBeforeToIsBlocked = diffAsMinutes < this.reserveMinTimeAsMinutes && time === timeLineBeforeTo
        const dayIsBlocked = idxCurrentTime < idxTimeFrom || idxCurrentTime > idxTimeTo || this.timelineTo === time
        const dayIsBusy = this.timesBusy.indexOf(time) !== -1

        if (timeBeforeToIsBlocked) return 'BLOCKED'
        if (dayIsBlocked) return 'BLOCKED'
        if (dayIsBusy) {
          return 'BUSY'
        } else {
          return 'FREE'
        }
      }
    },
    getStatusToCustomTimeLine(timeline, time) {
      if (this.isFullDayBlocked) {
        return 'BLOCKED'
      } else {
        const idxCurrentTime = timeline.indexOf(time)
        const idxTimeFrom = timeline.indexOf(this.timelineFrom)
        const idxTimeTo = timeline.indexOf(timeline[timeline.length - 3])

        const timeLineBeforeTo = timeline[timeline.length - 4]
        const utcTimeLineBeforeTo = this.getUTCTime(timeLineBeforeTo)
        const utcTimeLineTo = this.getUTCTime(timeline[timeline.length - 3])
        const diffAsMinutes = getDiffToMinutes(utcTimeLineBeforeTo, utcTimeLineTo)

        const timeBeforeToIsBlocked = diffAsMinutes < this.reserveMinTimeAsMinutes && time === timeLineBeforeTo
        const dayIsBusy = this.timesBusyToCustomTimeLine.indexOf(time) !== -1

        const dayIsBlocked = idxCurrentTime < idxTimeFrom || idxCurrentTime > idxTimeTo || timeline[timeline.length - 3] === time

        if (timeBeforeToIsBlocked) return 'BLOCKED'
        if (dayIsBlocked) return 'BLOCKED'
        if (dayIsBusy) {
          return 'BUSY'
        } else {
          return 'FREE'
        }
      }
    },
    getColorFromStatus(status) {
      switch (status) {
        case 'BLOCKED':
          return 'background: #ffffff !important; opacity: 0.5; pointer-events: none';
        case 'BUSY':
          return 'background: #ee7071bf; pointer-events: none'
        case 'FREE':
          return 'background: #5ed593'
      }
    },
    roundingNumber(time) {
      let HH = time.split(/:| /)[0]
      let AMPM = time.split(/:| /)[2]
      return `${HH}:${this.minutes} ${AMPM}`
    },
    selectTime(timeline) {
      const {hour, minutes, part} = splitStringTime(timeline.time)
      this.$store.commit('setSelectedStartTime', {hours: this.getHourIs24(hour, part), minutes})
      const reserveHours = this.reserveMinTime.split(':')[0]
      const reserveMinutes = this.reserveMinTime.split(':')[1]
      const selectedEndTime = addUpTime(`${this.getHourIs24(hour, part)}:${minutes}`, {
        hours: reserveHours,
        minutes: reserveMinutes
      })
      const selectedEndHours = selectedEndTime.split(':')[0]
      const selectedEndMinutes = selectedEndTime.split(':')[1]
      this.$store.commit('setSelectedEndTime', {hours: selectedEndHours, minutes: selectedEndMinutes})
    },
    getStartingPointFrom(timeLine, time) {
      const {hour: currentHours, minutes: currentMinutes, part: currentPart} = splitStringTime(time)
      const finishedTime = `${currentHours}:${this.minutes} ${currentPart}`

      const idxBeforeTime = timeLine.indexOf(timeLine.find(el => el === finishedTime)) - 1
      const beforeTime = timeLine[idxBeforeTime]

      if (!beforeTime) return finishedTime

      const {hour: beforeHours, minutes: beforeMinutes} = splitStringTime(beforeTime)
      const reserveMinTimeAsMinutes = moment.duration(this.reserveMinTime).asMinutes();

      const utcFinishedTime = {hours: currentHours, minutes: currentMinutes}
      const utcBeforeTime = {hours: beforeHours, minutes: beforeMinutes}

      if (!utcBeforeTime) return finishedTime

      const diffAsMinutes = getDiffToMinutes(utcBeforeTime, utcFinishedTime)

      if (diffAsMinutes < reserveMinTimeAsMinutes) {
        return beforeTime
      } else {
        return finishedTime
      }
    },
    getStartingPointFromToCustomTimeLine(timeLine, time) {
      const idxTime = timeLine.indexOf(timeLine.find(el => el === time))
      return timeLine[idxTime]
    },
    getEndPointTo(timeLine, time) {
      const {hour: currentHours, minutes: currentMinutes, part: currentPart} = splitStringTime(time)
      const finishedTime = `${currentHours}:${this.minutes} ${currentPart}`

      if (+currentMinutes <= +this.minutes) {
        return `${currentHours}:${this.minutes} ${currentPart}`
      } else {
        const idxAfterTime = timeLine.indexOf(timeLine.find(el => el === finishedTime)) + 1
        if (timeLine[idxAfterTime]) {
          return timeLine[idxAfterTime]
        } else return finishedTime
      }
    },
    getEndPointToCustomTimeLine(timeLine, time) {
      const idxTime = timeLine.indexOf(timeLine.find(el => el === time))
      return timeLine[idxTime]
    },
  },
  computed: {
    reserveMinTime() {
      return this.$store.getters.reserveMinTime
    },
    reserveMinTimeAsMinutes() {
      return moment.duration(this.reserveMinTime).asMinutes();
    },
    timelineDetails() {
      return this.$store.getters.timelineDetails
    },
    timelineFrom() {
      return this.timelineDetails?.from || '10:00 AM'
    },
    timelineTo() {
      return this.roundingNumber(this.timelineDetails?.to || '06:00 PM')
    },
    minutes() {
      return this.timelineFrom.split(/:| /)[1]
    },
    isFullDayBlocked() {
      return this.$store.getters.isFullDayBlocked
    },
    facilityTimes() {
      return this.$store.getters.facilityTimes
    },
    selectedBookingDate() {
      return this.$store.getters.selectedBookingDate
    },
    timesBusy() {
      let result = []
      let selectedDate = moment(this.selectedBookingDate.id).format('MM/DD/YYYY')
      let facilityTimesFromSelectedDate = this.facilityTimes.filter(el => el.FromDate === selectedDate)
      if (facilityTimesFromSelectedDate?.length) {
        facilityTimesFromSelectedDate.forEach(el => {
          const timeLine = this.timelines[this.minutes]
          const from = this.getStartingPointFrom(timeLine, el.From)
          const to = this.getEndPointTo(timeLine, el.To)
          result = result.concat(timeLine.slice(timeLine.indexOf(from), timeLine.indexOf(to)))
        })
      }
      let uniqueItems = []
      for (let item of result) {
        if (!uniqueItems.includes(item)) {
          uniqueItems.push(item);
        }
      }
      return uniqueItems
    },
    timesBusyToCustomTimeLine() {
      let result = []
      let selectedDate = moment(this.selectedBookingDate.id).format('MM/DD/YYYY')
      let facilityTimesFromSelectedDate = this.facilityTimes.filter(el => el.fromDate === selectedDate)
      if (facilityTimesFromSelectedDate?.length) {
        facilityTimesFromSelectedDate.forEach(el => {
          const timeLine = this.timelines
          const from = this.getStartingPointFromToCustomTimeLine(timeLine, el.from)
          const to = this.getEndPointToCustomTimeLine(timeLine, el.to)
          if (from && to)
            result = result.concat(timeLine.slice(timeLine.indexOf(from), timeLine.indexOf(to)))
        })
      }
      let uniqueItems = []
      for (let item of result) {
        if (!uniqueItems.includes(item)) {
          uniqueItems.push(item);
        }
      }
      return uniqueItems
    },
    actualTimeLines() {
      if (this.customTimeLine) {
        let result = []
        this.timelines.forEach(el => {
          result.push({
            time: el,
            status: this.getStatusToCustomTimeLine(this.timelines, el),
          })
        })
        this.$store.commit('setActualTimeLines', result)
        return result
      } else {
        let timeline = this.timelines[this.minutes]

        let indexFrom = +timeline.indexOf(this.timelineFrom)
        let indexTo = +timeline.indexOf(this.timelineTo)

        let lineFrom = indexFrom > 2 ? (indexFrom - 2) : 0
        let lineTo = indexTo <= 21 ? (indexTo + 3) : 24
        let times = timeline.slice(lineFrom, lineTo)
        let result = []

        times.forEach(el => {
          result.push({
            time: el,
            status: this.getStatus(times, el),
          })
        })
        this.$store.commit('setActualTimeLines', result)
        return result
      }
    }
  },
}
</script>

<style module lang="scss" src='../../../../../../assets/styles/amenities/styles.scss'/>