<template>
  <div :class="$style.rbs__container">
    <div :class="[$style.rbs__amenities_reservations_modal, $style.rbs__amenities_booking]">
      <div :class="$style.rbs__amenities_reservations_overlay"
           @click="hideWindow"></div>
      <div :class="$style.rbs__amenities_reservations_modal_page">
        <div :class="[$style.rbs__amenities_booking_loader, $style.rbs__datatable_loader]"
             v-if="modalLoader">
          <Loader/>
        </div>
        <div :class="$style.rbs__amenities_reservations_modal_title">
          make a reservation
        </div>
        <div v-if="error" :class="$style.rbs__amenities_booking_error">
          <div :class="$style.rbs__amenities_booking_error_message">
            {{ error }}
          </div>
          <img :src="'/img/widgets/icons/error_times.svg'"
               @click="error=null"
               alt="error_times"
               :class="$style.rbs__amenities_booking_error_hide">
        </div>
        <div :class="[$style.rbs__amenities_reservations_modal_content, $style.rbs__amenities_booking_content]">
          <div :class="$style.rbs__amenities_booking_section">
            <div :class="$style.rbs__amenities_booking_form">
              <div v-if="unitContactDetails.length > 1"
                   :class="$style.rbs__amenities_booking_form_row">
                <div :class="$style.rbs__amenities_booking_form_title">
                  Unit/Contact
                </div>
                <div :class="$style.rbs__amenities_booking_form_input">
                  <div>
                    <Select
                        @select="selectUnitContact"
                        @clear="clearUnitContact"
                        :height="'35px'"
                        :width="'185px'"
                        :font="'12px'"
                        :class="$style.rbs__amenities_booking_dropdown"
                        :items="unitContactDetails"
                        :placeholder="'Select Unit/Contact'"/>
                  </div>
                </div>
              </div>
              <div :class="$style.rbs__amenities_booking_form_row">
                <div :class="$style.rbs__amenities_booking_form_title">
                  Date
                </div>
                <div :class="$style.rbs__amenities_booking_form_input">
                  <div :class="[$style.rbs__amenities_booking_form_field, $style.rbs__amenities_booking_form_date]">
                    <Datepicker v-model="formData.date"
                                autoApply
                                @update:modelValue="selectNewDate"
                                :minDate="new Date()"
                                :enableTimePicker="false"/>
                    <img :src="'/img/widgets/icons/calendar_lite_icon.svg'"
                         alt="calendar">
                  </div>
                </div>
              </div>
              <div :class="$style.rbs__amenities_booking_form_row">
                <div :class="[$style.rbs__amenities_booking_form_title,
                !startTime ? $style.rbs__amenities_booking_form_title_blocked : null]">
                  Start Time
                </div>
                <div :class="$style.rbs__amenities_booking_form_input">
                  <div :class="[$style.rbs__amenities_booking_form_field, $style.rbs__amenities_booking_form_date]">
                    <Datepicker v-model="formData.start"
                                :disabled="!startTime"
                                timePicker
                                autoApply
                                :minutesIncrement="minutesIncrement"
                                :startTime="startTime || {hours: 0, minutes: 0}"
                                @update:modelValue="updateStartTime"
                                modeHeight="120"
                                :is24="false">
                      <template #am-pm-button="{ toggle, value }">
                        <button class="rbs-bg-color rbs-font-color"
                                :class="$style.rbs__amenities_booking_form_btn"
                                @click="toggle">{{ value }}
                        </button>
                      </template>
                    </Datepicker>
                    <img :src="'/img/widgets/icons/clock_icon.svg'"
                         alt="calendar">
                  </div>
                </div>
              </div>
              <div :class="$style.rbs__amenities_booking_form_row">
                <div :class="[$style.rbs__amenities_booking_form_title,
                !endTime ? $style.rbs__amenities_booking_form_title_blocked : null]">
                  End Time
                </div>
                <div :class="$style.rbs__amenities_booking_form_input">
                  <div :class="[$style.rbs__amenities_booking_form_field, $style.rbs__amenities_booking_form_date]">
                    <Datepicker v-model="formData.end"
                                :disabled="!endTime"
                                timePicker
                                autoApply
                                :minutesIncrement="minutesIncrement"
                                :startTime="endTime || {hours: 0, minutes: 0}"
                                @update:modelValue="updateEndTime"
                                modeHeight="120"
                                :is24="false">
                      <template #am-pm-button="{ toggle, value }">
                        <button class="rbs-bg-color rbs-font-color"
                                :class="$style.rbs__amenities_booking_form_btn"
                                @click="toggle">{{ value }}
                        </button>
                      </template>
                    </Datepicker>
                    <img :src="'/img/widgets/icons/clock_icon.svg'"
                         alt="calendar">
                  </div>
                </div>
              </div>
              <div :class="$style.rbs__amenities_booking_form_row">
                <div :class="[$style.rbs__amenities_booking_form_title,
                              !endTime || !startTime ? $style.rbs__amenities_booking_form_title_blocked : null]">
                  Attendees
                </div>
                <div :class="[$style.rbs__amenities_booking_form_input,
                               !endTime || !startTime ? $style.rbs__amenities_booking_form_input_blocked : null]">
                  <input type="number"
                         v-model="formData.attendees"
                         @input="onInput"
                         inputmode="numeric"
                         pattern="[0-9]*"
                         :class="[$style.rbs__amenities_booking_form_field]">
                </div>
              </div>
            </div>
            <div :class="$style.rbs__amenities_booking_section_info">
              <div :class="$style.rbs__amenities_booking_section_info_item">
                <div :class="$style.rbs__amenities_booking_section_info_name">
                  Facility
                </div>
                <div :class="$style.rbs__amenities_booking_section_info_value">
                  <span v-if="amenity?.name">{{ amenity.name }}</span>
                  <span v-else :class="$style.rbs__amenities_booking_section_info_value_empty"></span>
                </div>
              </div>
              <div :class="$style.rbs__amenities_booking_section_info_item">
                <div :class="$style.rbs__amenities_booking_section_info_name">
                  PAYMENT TYPE
                </div>
                <div :class="$style.rbs__amenities_booking_section_info_value">
                  <span v-if="amenity?.paymentType">{{ amenity.paymentType }}</span>
                  <span v-else :class="$style.rbs__amenities_booking_section_info_value_empty"></span>
                </div>
              </div>
              <div :class="$style.rbs__amenities_booking_section_info_item">
                <div :class="$style.rbs__amenities_booking_section_info_name">
                  Building
                </div>
                <div :class="$style.rbs__amenities_booking_section_info_value">
                  <span v-if="amenity?.building">{{ amenity.building }}</span>
                  <span v-else :class="$style.rbs__amenities_booking_section_info_value_empty"></span>
                </div>
              </div>
              <div :class="$style.rbs__amenities_booking_section_info_item">
                <div :class="$style.rbs__amenities_booking_section_info_name">
                  Price
                </div>
                <div :class="$style.rbs__amenities_booking_section_info_value">
                  <span v-if="amenity?.price">${{ amenity.price }} / Per Hour</span>
                  <span v-else :class="$style.rbs__amenities_booking_section_info_value_empty"></span>
                </div>
              </div>
              <div :class="$style.rbs__amenities_booking_section_info_item">
                <div :class="$style.rbs__amenities_booking_section_info_name">
                  open hours
                </div>
                <div :class="$style.rbs__amenities_booking_section_info_value">
                <span v-if="timelineDetails?.from && timelineDetails?.to">
                 {{ selectedBookingDay }} {{ timelineDetails.from }} - {{ timelineDetails.to }}
                </span>
                  <span v-else :class="$style.rbs__amenities_booking_section_info_value_empty"></span>
                </div>
              </div>
            </div>
          </div>
          <div :class="$style.rbs__amenities_booking_section">
            <CalendarWindowHead/>
            <AmenitiesDetailsTimeLine v-if="timelineView"/>
            <AmenitiesDetailsCalendar v-else/>
            <div :class="$style.rbs__amenities_booking_section_info_mob">
              <div :class="$style.rbs__amenities_booking_section_info_item">
                <div :class="$style.rbs__amenities_booking_section_info_name">
                  Facility
                </div>
                <div :class="$style.rbs__amenities_booking_section_info_value">
                  <span v-if="amenity?.name">{{ amenity.name }}</span>
                  <span v-else :class="$style.rbs__amenities_booking_section_info_value_empty"></span>
                </div>
              </div>
              <div :class="$style.rbs__amenities_booking_section_info_item">
                <div :class="$style.rbs__amenities_booking_section_info_name">
                  Building
                </div>
                <div :class="$style.rbs__amenities_booking_section_info_value">
                  <span v-if="amenity?.building">{{ amenity.building }}</span>
                  <span v-else :class="$style.rbs__amenities_booking_section_info_value_empty"></span>
                </div>
              </div>
              <div :class="$style.rbs__amenities_booking_section_info_item">
                <div :class="$style.rbs__amenities_booking_section_info_name">
                  open hours
                </div>
                <div :class="$style.rbs__amenities_booking_section_info_value">
                <span v-if="timelineDetails?.from && timelineDetails?.to">
                 {{ selectedBookingDay }} {{ timelineDetails.from }} - {{ timelineDetails.to }}
                </span>
                  <span v-else :class="$style.rbs__amenities_booking_section_info_value_empty"></span>
                </div>
              </div>
              <div :class="$style.rbs__amenities_booking_section_info_item">
                <div :class="$style.rbs__amenities_booking_section_info_name">
                  PAYMENT TYPE
                </div>
                <div :class="$style.rbs__amenities_booking_section_info_value">
                  <span v-if="amenity?.paymentType">{{ amenity.paymentType }}</span>
                  <span v-else :class="$style.rbs__amenities_booking_section_info_value_empty"></span>
                </div>
              </div>
              <div :class="$style.rbs__amenities_booking_section_info_item">
                <div :class="$style.rbs__amenities_booking_section_info_name">
                  Price
                </div>
                <div :class="$style.rbs__amenities_booking_section_info_value">
                  <span v-if="amenity?.price">${{ amenity.price }} / Per Hour</span>
                  <span v-else :class="$style.rbs__amenities_booking_section_info_value_empty"></span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div :class="$style.rbs__amenities_reservations_modal_bottom">
          <div :class="$style.rbs__amenities_reservations_modal_amount">
            <span>Total Amount</span>
            <span v-if="totalPrice">${{ totalPrice }}</span>
            <span v-else>-</span>
          </div>
          <div :class="$style.rbs__amenities_reservations_modal_actions">

            <Button @click="hideWindow"
                    :transparent_theme="true"
                    :text="'cancel'"/>
            <Button :class="[$style.rbs__amenities_details_btn,
           isBlockedBooking ? $style.rbs__amenities_details_btn_blocked : null]"
                    @click="bookingNow"
                    :text="'book now'"/>

          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import Select from '../../../../../ui/select-component'
import AmenitiesDetailsCalendar from './calendar/amenities-calendar-component'
import AmenitiesDetailsTimeLine from './calendar/amenities-timeline-component'
import CalendarWindowHead from './calendar/amenities-window-calendar-head-component'
import {
  addUpTime,
  currentDateDetails,
  getHourIs12,
  getHourIs24,
  splitStringTime,
  subtractTime
} from '@/utils/time_operations'
import Datepicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css'
import Button from '../../../../../ui/button-component'
import Loader from '@/ui/loader-component'
import {useToast} from "vue-toastification";
import moment from "moment";

export default {
  name: "reservations-details-modal-component",
  components: {
    Datepicker,
    Button,
    AmenitiesDetailsCalendar,
    AmenitiesDetailsTimeLine,
    CalendarWindowHead,
    Select,
    Loader,
  },
  data: () => ({
    toast: '',
    error: null,
    formData: {
      unitId: null,
      date: null,
      start: null,
      end: null,
      attendees: 1,
    },
    totalPrice: null
  }),
  mounted() {
    document.querySelector('body').style.overflow = 'hidden'
    if (this.selectedBookingDate) {
      this.formData.date = moment.utc(this.selectedBookingDate, 'YYYY-MM-DD').format('DD MMM YYYY')
    } else {
      this.formData.date = null
    }

    if (this.unitContactDetails.length === 1)
      this.formData.unitId = this.unitContactDetails[0].children[0].id
    this.toast = useToast()
  },
  beforeUnmount() {
    this.formData = {
      unitId: null,
      date: null,
      start: null,
      end: null,
      attendees: 1,
    }
    this.totalPrice = null
  },
  setup() {
    return {
      getHourIs12,
      getHourIs24,
      splitStringTime,
      subtractTime,
      addUpTime,
      currentDateDetails,
    }
  },
  methods: {
    onInput(e) {
      this.formData.attendees = Math.max(1, parseInt(e.target.value) || 1);
    },
    checkingComplianceDates(data) {
      const startTime = `${data.start.hours}:${data.start.minutes}`
      const endTime = `${data.end.hours}:${data.end.minutes}`
      const startTime12 = this.getHourIs12(startTime)
      const endTime12 = this.getHourIs12(endTime)
      const foundTimeStart = this.actualTimeLine.find(el => el.time === startTime12)
      const foundTimeEnd = this.actualTimeLine.find(el => el.time === endTime12)
      return !!foundTimeStart && !!foundTimeEnd
    },
    async bookingNow() {
      if (!this.searchForErrorsBeforeSending()) {
        if (!this.formData.unitId)
          this.formData.unitId = this.unitContactDetails[0].children[0].id
        await this.bookingAmenity()
      }
    },
    searchForErrorsBeforeSending() {
      if (!this.unitContactDetails?.length) {
        this.error = 'There are no unitContacts in the system, booking is not possible'
        return true
      }
      if (!!this.amenity.capacity && this.amenity.capacity < this.formData.attendees) {
        this.error = 'You have reached the limit of allowed capacity'
        return true
      }
      if (!this.checkingComplianceDates(this.formData)) {
        this.error = 'The start or end time of booking is not included in the list of available'
        return true
      }
    },
    async bookingAmenity() {
      try {
        await this.$store.commit('clearOldStatusBooking')
        await this.$store.dispatch('bookingAmenity', this.formData)
      } catch (e) {
        this.toast.error(e.message, 3000)
      } finally {
        if (this.statusBooking.isSuccess) {
          await this.hideWindow()
          await this.$store.commit('showSuccessNotification')
          await this.$store.commit('clearOldStatusBooking')
        } else {
          this.error = this.statusBooking.message
        }
        this.$store.commit('hideModalLoader')
      }
    },
    getTotalPrice() {
      this.isBlockedBooking
          ? this.totalPrice = null
          : this.calculateTotalPrice()
    },
    calculateTotalPrice() {
      const diff = moment.utc(moment.duration(this.formData.end) - moment.duration(this.formData.start)).format('HH:mm')
      const diffAsHours = moment.duration(diff).asHours()
      diffAsHours === 0
          ? this.totalPrice = this.amenity.price
          : this.totalPrice = +this.amenity.price * +diffAsHours
    },
    hideWindow() {
      this.$store.commit('hideBookWindow')
      this.$store.commit('clearOverAllTime')
      const {currentDate, monthName, monthNumber, year} = this.currentDateDetails()
      this.$store.dispatch('getCalendarActiveMonth', {name: monthName, number: monthNumber, year})
      this.$store.dispatch('getCalendarCurrentDate', currentDate)
    },
    async selectNewDate(date) {
      if (date) {
        await this.$store.dispatch('getSelectedBookingDate', {id: moment(date).format('YYYY-MM-DD')})
        await this.$store.dispatch('getTimeLine', {id: moment(date).format('YYYY-MM-DD')})
        this.$store.commit('setSelectedEndTime', null)
        this.$store.commit('setSelectedStartTime', null)
      } else {
        this.$store.commit('clearOverAllTime')
        this.$store.commit('activateTabMonth')
        this.$store.commit('deactivationTabDay')
        this.$store.commit('hideTimeLine')
      }
    },
    updateStartTime(time) {
      if (time.minutes === 0) time.minutes = '00'
      this.formData.start = time
      this.getTotalPrice()
      const reserveMinTimeAsMinutes = moment.duration(this.reserveMinTime).asMinutes();
      const diff = moment.utc(moment.duration(this.formData.end) - moment.duration(time)).format('HH:mm')
      const diffAsMinutes = moment.duration(diff).asMinutes()
      if (diffAsMinutes < reserveMinTimeAsMinutes) {
        const reserveHours = this.reserveMinTime.split(':')[0]
        const reserveMinutes = this.reserveMinTime.split(':')[1]
        const selectedEndTime = addUpTime(`${time.hours}:${time.minutes}`, {
          hours: reserveHours,
          minutes: reserveMinutes
        })
        const selectedEndHours = selectedEndTime.split(':')[0]
        const selectedEndMinutes = selectedEndTime.split(':')[1]
        this.$store.commit('setSelectedEndTime', {hours: selectedEndHours, minutes: selectedEndMinutes})
      }
    },
    updateEndTime(time) {
      if (time.minutes === 0) time.minutes = '00'
      this.formData.end = time
      this.getTotalPrice()
      const reserveMinTimeAsMinutes = moment.duration(this.reserveMinTime).asMinutes();
      const diff = moment.utc(moment.duration(time) - moment.duration(this.formData.start)).format('HH:mm')
      const diffAsMinutes = moment.duration(diff).asMinutes()
      if (diffAsMinutes < reserveMinTimeAsMinutes) {
        const reserveHours = this.reserveMinTime.split(':')[0]
        const reserveMinutes = this.reserveMinTime.split(':')[1]
        const selectedStartTime = subtractTime(`${time.hours}:${time.minutes}`, {
          hours: reserveHours,
          minutes: reserveMinutes
        })
        const selectedStartHours = selectedStartTime.split(':')[0]
        const selectedStartMinutes = selectedStartTime.split(':')[1]
        this.$store.commit('setSelectedStartTime', {hours: selectedStartHours, minutes: selectedStartMinutes})
      }
    },
    selectUnitContact(item) {
      this.formData.unitId = item.children[0].id
    },
    clearUnitContact() {
      this.formData.unitId = null
    },
  },
  computed: {
    actualTimeLine() {
      return this.$store.getters.actualTimeLine
    },
    reserveMinTime() {
      return this.$store.getters.reserveMinTime
    },
    minutesIncrement() {
      return this.$store.getters.reserveMinTime.split(':')[1]
    },
    amenity() {
      return this.$store.getters.detailsAmenity
    },
    timelineView() {
      return this.$store.getters.timelineView
    },
    timelineDetails() {
      return this.$store.getters.timelineDetails
    },
    activeTabDay() {
      return this.$store.getters.activeTabDay
    },
    selectedBookingDay() {
      return moment(this.$store.getters.selectedBookingDate?.id).format('dddd')
    },
    selectedBookingDate() {
      return this.$store.getters.selectedBookingDate?.id
    },
    startTime() {
      if (this.timelineDetails?.from) {
        const {hour, minutes, part} = splitStringTime(this.timelineDetails.from)
        return {hours: this.getHourIs24(hour, part), minutes}
      } else return false
    },
    endTime() {
      if (this.timelineDetails?.to) {
        const {hour, minutes, part} = splitStringTime(this.timelineDetails.to)
        return {hours: this.getHourIs24(hour, part), minutes}
      } else return false
    },
    selectedStartTime() {
      return this.$store.getters.selectedStartTime
    },
    selectedEndTime() {
      return this.$store.getters.selectedEndTime
    },
    unitContactDetails() {
      return this.$store.getters.unitContactDetails
    },
    isBlockedBooking() {
      return !this.endTime
          || !this.startTime
          || !this.formData.date
          || !this.formData.start
          || !this.formData.end
          || !this.formData.attendees
    },
    modalLoader() {
      return this.$store.getters.modalLoader
    },
    statusBooking() {
      return this.$store.getters.statusBooking
    },
  },
  watch: {
    selectedBookingDate(newValue) {
      this.formData.date = moment.utc(newValue, 'YYYY-MM-DD').format('DD MMM YYYY')
    },
    selectedStartTime(newValue) {
      this.formData.start = newValue
      this.getTotalPrice()
    },
    selectedEndTime(newValue) {
      this.formData.end = newValue
      this.getTotalPrice()
    },
  }
}
</script>

<style module lang="scss" src='../../../../../assets/styles/amenities/styles.scss'/>