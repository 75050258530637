export const getters = {
    amenities: state => state.amenities,
    amenitiesTotalCount: state => state.amenitiesTotalCount,
    reservations: state => state.reservations,
    reservationsTotalCount: state => state.reservationsTotalCount,
    isHome: state => !state.selectedAmenityDetails,
    detailsReservation: state => state.selectedReservationDetails,
    detailsAmenity: state => state.selectedAmenityDetails,
    facilityTimes: state => state.facilityTimes,
    isFullDayBlocked: state => state.isFullDayBlocked,
    unitContactDetails: state => state.unitContactDetails,
    activeMonth: state => state.activeMonth,
    currentDate: state => state.currentDate,
    viewPortDates: state => state.viewPortDates,
    bookWindowView: state => state.bookWindowView,
    selectedBookingDate: state => state.selectedBookingDate,
    timelineDetails: state => state.timelineDetails,
    timelineView: state => state.timelineView,
    activeTabDay: state => state.activeTabDay,
    activeTabMonth: state => state.activeTabMonth,
    buildingList: state => state.buildingList,
    responseEdition: state => state.responseEdition,
    responseEditionWidth: state => state.responseEditionWidth,
    searchValue: state => state.searchValue,
    pageLoader: state => state.pageLoader,
    amenitiesLoader: state => state.amenitiesLoader,
    modalLoader: state => state.modalLoader,
    reservationLoader: state => state.reservationLoader,
    buildingId: state => state.buildingId,
    reserveMinTime: state => state.reserveMinTime,
    reserveMaxTime: state => state.reserveMaxTime,
    selectedStartTime: state => state.selectedStartTime,
    selectedEndTime: state => state.selectedEndTime,
    statusBooking: state => state.statusBooking,
    successNotification: state => state.successNotification,
    widgetPageUrl: state => state.widgetPageUrl,
    homeAmenitiesPage: state => state.homeAmenitiesPage,
    detailsAmenityPage: state => state.detailsAmenityPage,
    actualTimeLine: state => state.actualTimeLine,
    contactId: state => state.contactId,
    windowDeleteReservation: state => state.windowDeleteReservation,
    amenityDeleteId: state => state.amenityDeleteId,
    reservationsDetailsModal: state => state.reservationsDetailsModal,
    detailsReservationLoader: state => state.detailsReservationLoader,
}
