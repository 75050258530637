<template>
  <div :class="$style.rbs__datatable_loader">
    <svg xmlns="http://www.w3.org/2000/svg"
         style="margin: auto; display: block; shape-rendering: auto;" width="200px"
         height="200px" viewBox="0 0 100 100" preserveAspectRatio="xMidYMid">
      <circle cx="50" cy="50" fill="none" stroke="#7f7777" stroke-width="10" r="35"
              stroke-dasharray="164.93361431346415 56.97787143782138">
        <animateTransform attributeName="transform" type="rotate" repeatCount="indefinite" dur="1s"
                          values="0 50 50;360 50 50" keyTimes="0;1"></animateTransform>
      </circle>
    </svg>
  </div>
</template>

<script>
export default {
  name: "loader-component"
}
</script>

<style module lang="scss" src='../assets/styles/elements/data_table.scss'/>