import {computed} from "vue";


export const useFilter = (items, responsive, filter = null) => {
    const clearFilterValue = key => {
        filter.value.fields[key] = ''
        filter.value.mobile.search = ''
    }
    const filteredItems = computed(() => {
        if (filter?.value) {
            if (!responsive.value) {
                if (items.value?.length) {
                    return items.value.filter(el =>
                        Object.keys(filter.value.fields).reduce((acc, filterKey) => {
                            let filterCondition = filter.value.fields[filterKey];
                            let filterMatch = filter.value.match[filterKey];
                            let item = el[filter.value.keys[filterKey]]
                            if (filterCondition !== '') {
                                if (item) {
                                    switch (filterMatch) {
                                        case 'partial':
                                            return (acc && item.toLowerCase().includes(filterCondition.toLowerCase()));
                                        case 'full':
                                            return (acc && item.toLowerCase() === filterCondition.toLowerCase());
                                    }
                                }
                            } else return acc
                        }, true)
                    )
                }
            } else {
                let search = filter.value.mobile.search
                if (items.value?.length) {
                    return items.value.filter(el => {
                        let result = true
                        if (search) {
                            result = Object.values(filter.value.mobile.keys)
                                .some(key => {
                                    if (el[key])
                                        return el[key].toLowerCase().includes(search.toLowerCase())
                                })
                            if (!result) return false
                        }
                        if (Object.keys(filter.value.mobile.fields).length) {
                            let fields = filter.value.mobile.fields
                            Object.keys(fields).forEach(key => {
                                if (fields[key]) {
                                    result = fields[key].toLowerCase() === el[filter.value.keys[key]].toLowerCase()
                                }
                            })
                        }
                        return result
                    })
                }

            }
        } else return items.value || []
        return []
    })

    return {filteredItems, clearFilterValue}
}