<template>
  <tbody v-if="!mobile">
  <tr>
    <td colspan="8" :class="$style.rbs__datatable_empty">
      <span v-html="icon"></span>
      {{ text }}
    </td>
  </tr>
  </tbody>
  <div v-else :class="[$style.rbs__datatable_empty, $style.mobile]">
    <span v-html="icon"></span>
    {{ text }}
  </div>
</template>

<script>
export default {
  name: "table-no-items-component",
  props: {
    mobile: {
      type: Boolean,
      default: false
    },
    text: {
      type: String,
      default: 'No Items'
    }
  },
  setup() {
    const icon =
        `<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <circle cx="12" cy="12" r="9" fill="#2A4157" fill-opacity="0.24"/>
          <path d="M16 8L8 16" stroke="#222222" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round"/>
          <path d="M8 8L16 16" stroke="#222222" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>`
    return {icon}
  },
}
</script>

<style module lang="scss" src='../../assets/styles/elements/data_table.scss'/>